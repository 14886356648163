import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@firebird-web/shared-config';
import { apiEndpoints } from '@firebird-web/shared-constants';
import { CommonUtils } from '@firebird-web/shared-utils';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WeightedRegionsService {
  public getWeightedIsoRegions(continent: string, isWidget = false) {
    const baseApi = `${environment.apiDomain}/api/v1/WeightedIsoCountry/regions/${continent}`;
    const endPointUrl = !isWidget ? baseApi : `${baseApi}/${isWidget}`;

    return this.http.get(endPointUrl).pipe(map((resp: any) => resp?.data));
  }

  /**
   * getWeightedDegreeDaysRegions
   * @param continet
   * @returns
   */
  public getWeightedDegreeDaysRegions(continent: string) {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getWeightedDegreeDaysRegions}`,
      { continent }
    );
    return this.http.get(endPointUrl).pipe(map((resp: any) => resp?.data));
  }

  /**
   * getWeightedUsGasBcfRegions
   * @param continet
   * @returns
   */
  public getWeightedUsGasBcfRegions(continent: string) {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getWeightedUsGasBcfRegions}`,
      { continent }
    );
    return this.http.get(endPointUrl).pipe(map((resp: any) => resp?.data));
  }

  // constructor
  public constructor(private http: HttpClient) {}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@firebird-web/shared-config';
import {
  baseChartOptions,
  baseSeriesTemplate,
  seriesModelRunMap,
  startLegendState,
} from '../models/load.models';
import * as Highcharts from 'highcharts';
import { DecimalPipe } from '@angular/common';
import { Observable, shareReplay, map } from 'rxjs';
import { UtcDatePipe } from '@firebird-web/shared/pipes';
import {
  LoadDailyData,
  LoadRegion,
} from '../models/load-daily-table.interfaces';
import { formStyle, isThreeDashesLineGraphIcon } from '../utils';
import { seriesModelRunOrderMap } from 'libs/renewable/src/lib/constants/baseChartOptions';
import { ExportDataService } from '@firebird-web/shared-services';
import {
  LoadData,
  ChartRange,
  CustomSeries,
  Forecasts,
  LoadHourlyGraphData,
  LoadHourlyItem,
} from '../models/load.interfaces';
import { COMMON_MODELS, DEFAULT_REGION } from '../constants/load.constants';
import { first } from 'lodash';

@Injectable()
export class LoadDataService {
  constructor(
    private readonly http: HttpClient,
    private readonly datePipe: UtcDatePipe,
    private readonly exportService: ExportDataService,
    private readonly decimalPipe: DecimalPipe
  ) {}

  public downloadLoadHourlyDataCSV(
    source: string,
    locationId: string
  ): Observable<void> {
    const endPointUrl = `${environment.apiDomain}/api/v1/Load/hourly/table/download`;

    const payload = {
      source,
      locationId,
    };

    return this.http
      .post(endPointUrl, payload, { responseType: 'blob' })
      .pipe(
        map((response) =>
          this.exportService.downloadFile(
            response,
            `DemandForecastHourlyTable_${source.replace(
              'WSI',
              'AG2'
            )}_${locationId}.csv`
          )
        )
      );
  }

  public downloadLoadDailyDataCSV(
    iso: string,
    forecastDate: string,
    modelRun: string,
    calcType: string,
    locationId: string,
    showDifferences = false
  ): Observable<void> {
    const endPointUrl = `${environment.apiDomain}/api/v1/Load/daily/table/download`;

    const payload = {
      iso,
      forecastDate,
      modelRun,
      calcType,
      showDifferences,
      regions: [locationId],
      subZones: [],
    };

    return this.http
      .post(endPointUrl, payload, { responseType: 'blob' })
      .pipe(
        map((response) =>
          this.exportService.downloadFile(
            response,
            `DemandForecastDailyTable__${iso}_${forecastDate}.csv`
          )
        )
      );
  }

  public getLocations$() {
    const endPointUrl = `${environment.apiDomain}/api/v1/load/location/configs`;
    return this.http.get<LoadRegion[]>(endPointUrl);
  }

  public getHourlyTableData$(locationId = DEFAULT_REGION, source = 'WSI') {
    const endPointUrl = `${environment.apiDomain}/api/v1/load/hourly-table/${source}/${locationId}`;
    return this.http.get<LoadData>(endPointUrl);
  }

  public getDailyTableData$(locationId = DEFAULT_REGION) {
    const endPointUrl = `${environment.apiDomain}/api/v1/load/daily-table/${locationId}`;
    return this.http.get<LoadDailyData>(endPointUrl);
  }

  public getLoadHourlyGraph$(
    locationId: string,
    source = 'LOAD_FCST_NA'
  ): Observable<LoadHourlyGraphData> {
    const subLink = `api/v1/Load/hourly-graph/${locationId}/${source}`;
    const link = `${environment.apiDomain}/${subLink}`;
    return this.http
      .get<LoadHourlyGraphData>(link)
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  public buildChartOptions(
    data: LoadHourlyGraphData,
    legendState: Record<string, boolean>,
    range: ChartRange
  ): Highcharts.Options {
    const series = this.buildSeries(data?.items, legendState);
    const datePipe = this.datePipe;
    const decimalPipe = this.decimalPipe;

    const rangeData =
      range.min && range.max && range.min < range.max
        ? {
            min: range.min,
            max: range.max,
          }
        : {};

    return {
      ...baseChartOptions,
      xAxis: [
        {
          ...baseChartOptions?.xAxis,
          ...rangeData,
        },
      ],
      series,
      tooltip: {
        ...baseChartOptions.tooltip,
        formatter: function (this: Highcharts.TooltipFormatterContextObject) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const tooltip = this;
          const date = tooltip['x'] as number;
          const formattedDate = Highcharts.dateFormat('%A, %b %d', date);
          const time = datePipe.transform(tooltip['x'], 'HH:mm');
          const convertedTime = time === '0' ? 24 : time;
          const day = [
            `<div style="color: #6C7C84; margin-top: 10px; margin-bottom: 15px; font-weight: 700; font-size: 13px;">
              <div style="text-align: center; text-transform: capitalize; margin-bottom: 5px;">Power Demand (Mw)</div>
              <div style="text-align: center;">${formattedDate}, ${convertedTime}</div>
            </div>`,
          ].join('');
          let prevColor: string;
          let prevName: string;
          const dataPoints = tooltip.points
            ?.filter(({ series }) => series.type === 'spline')
            .map(({ series, y }: any, index) => {
              const isCommonModel = (name: string) =>
                COMMON_MODELS.some((model) => name.includes(model));
              const isCommonModels = isCommonModel(series.name);
              const isFirstCommonModel = !prevName || !isCommonModel(prevName);
              const isFirstInGroup = isCommonModels
                ? isFirstCommonModel
                : prevColor !== series.color;

              prevColor = series.color;
              prevName = series.name;

              const yValue = decimalPipe.transform(Math.round(y).toString());
              const style = {
                color: '#6C7C84',
                display: 'flex',
                'justify-content': 'space-between',
                'font-size': '12px',
                'margin-top': isFirstInGroup && index !== 0 ? '27px' : '8px',
                'margin-bottom':
                  index + 1 === tooltip.points?.length ? '20px' : 'inherit',
                'padding-left': '28px',
                'padding-right': '21px',
              };
              const isThreeDashesLineIcon = isThreeDashesLineGraphIcon(
                series.userOptions.lineIcon
              );

              return [
                `<div style='${formStyle(style)}'>
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      gap: 0.375rem;
                      padding-right: 1.25rem;
                    "
                  >
                    <span
                      style="
                        display: inline-flex;
                        width: 1.25rem;
                        height: 1.25rem;
                        margin-left: ${isThreeDashesLineIcon ? '0.125rem' : 0};
                        stroke:${series.color};
                        fill:${series.color};
                        stroke-width:2;
                        fill-opacity:1;
                      "
                    >
                      ${series.userOptions.svgIcon}
                    </span>
                    <span
                      style="
                        display: inline-flex;
                        margin-left: ${isThreeDashesLineIcon ? '-0.125rem' : 0};
                      "
                    >
                      ${series.name}
                    </span>
                  </div>
                  <div style="font-weight: bold; line-height: 17px">
                    ${yValue}
                  </div>
                </div>`,
              ].join('');
            })
            .join('');

          return [day, dataPoints].join('');
        },
      },
    };
  }

  private buildSeries(
    allItems: LoadHourlyItem[],
    legendState: Record<string, boolean>
  ): CustomSeries[] {
    return allItems.reduce((series, item, i) => {
      if (series.find(({ id }) => item.display === id)) {
        return series;
      }

      const name = item.model.replace('_DAY_AHEAD', '');
      const seriesModelRunDef = seriesModelRunMap[name] || {};
      const index = allItems
        .filter((allItem) => allItem.model.includes(name))
        .indexOf(item);

      const seriesModelRunOrderDef = item.model.includes('OBS')
        ? this.obsModelMap(item.model)
        : seriesModelRunOrderMap[index] || {};

      // Build guide for forecast / forecast mean
      const field = 'loadValue';
      const modelName = item.display.split('- ')[0];
      const data = this.buildData(item.hourlyForecastData, field);
      const isVisible = Object.entries(legendState).find(
        ([key]) => modelName === key
      )?.[1];
      const isVisibleByDefault = Object.entries(startLegendState).find(
        ([key]) => modelName.includes(key)
      )?.[1];
      const visible = isVisible ?? isVisibleByDefault ?? false;

      const seriesGuide: CustomSeries = {
        ...baseSeriesTemplate,
        ...seriesModelRunDef,
        ...seriesModelRunOrderDef,
        marker: {
          enabled: false,
        },
        showInNavigator: true,
        name: this.getDisplayName(
          item.model.replace('_', ' '),
          item.display,
          item.initTime,
          item.locationTz
        ),
        data,
        modelRunOrder: i,
        id: item.display.split('- ')[0],
        visible: visible && data.length > 0,
        showInLegend: true,
      };

      series.push(seriesGuide);
      return series;
    }, [] as CustomSeries[]);
  }

  private getDisplayName(
    model: string,
    display: string,
    initTime: string,
    locationTz: string
  ): string | undefined {
    if (model === 'OBS INSTANTANEOUS') {
      return '5 min Obs';
    }
    if (model === 'OBS') {
      return 'Hourly Obs';
    }
    if (model === 'OBS NET') {
      return 'Obs Net Load';
    }
    if (COMMON_MODELS.includes(model)) {
      return `${model} ${this.datePipe.transform(
        initTime,
        'MMM dd'
      )} ${display.slice(-3)}`;
    }
    return `${display.split('- ')[0]} ${this.datePipe.transform(
      initTime,
      'MMM dd, haaa'
    )} ${locationTz}`;
  }

  private buildData(
    items: Forecasts[],
    field: keyof Forecasts
  ): Array<[number, number | null | string]> {
    return items.map((item: Forecasts) => {
      const localValidTime = new Date(item.validTime + 'Z').getTime();
      const value = item[field] as number;
      return [localValidTime, Math.round(value)];
    });
  }

  private obsModelMap(model: string) {
    if (model === 'OBS_INSTANTANEOUS') {
      return seriesModelRunOrderMap[1];
    }

    if (model === 'OBS_NET') {
      return seriesModelRunOrderMap[2];
    }

    return first(seriesModelRunOrderMap);
  }
}

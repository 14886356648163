import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, shareReplay, tap } from 'rxjs';
import {
  ChartRange,
  CustomSeries,
  RenewableProductGraph,
  RenewablesData,
  RenewablesEnsSpread,
  RenewablesGraphData,
  RenewablesGraphItem,
  RenewablesProducts,
  RenewablesResponse,
} from '../../../models';
import { ServerResponse } from '@firebird-web/shared-interfaces';
import { defaultAxisLabelsStyle } from '@firebird-web/shared-constants';
import {
  baseBandTemplate,
  baseChartOptions,
  baseSeriesTemplate,
  seriesModelRunMap,
  seriesModelRunOrderMap,
  seriesToSkip,
  startEurWidgetLegendState,
  startLegendState,
} from '../../../constants';
import * as Highcharts from 'highcharts';
import { isThreeDashesLineGraphIcon } from '../../../utils';
import { EurRenewablesNetworkingService } from './eur-renewables-networking.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import HC_stock from 'highcharts/modules/stock';
import { RenewablesRunDates, TableProps } from '../types';
import { DropdownOption } from '../../../../../../shared/interfaces/src/lib';

HC_stock(Highcharts);

Highcharts.AST.allowedTags.push('line');
Highcharts.AST.allowedAttributes.push(
  'viewBox',
  'stroke-dasharray',
  'preserveAspectRatio',
  'fit',
  'focusable',
  'xmlns'
);

const formStyle = (st: Record<string, string>) =>
  Object.entries(st).reduce<string>((acc, [key, value]) => {
    return `${acc} ${key}: ${value};`;
  }, '');

@Injectable()
export class EurRenewablesService {
  public maxCapacity = '';
  private versions = new Map();

  constructor(
    private readonly networking: EurRenewablesNetworkingService,
    private readonly datePipe: DatePipe,
    private readonly decimalPipe: DecimalPipe
  ) {}

  public get renewablesDefaultEnsembleSpreads(): RenewablesEnsSpread {
    return {
      value: 'none',
      label: 'No Ensemble Spread',
    };
  }

  public getGraphRenewables$(
    region: string
  ): Observable<ServerResponse<RenewablesGraphData>> {
    const subLink = `api/v1/renewables/renewables-graph/${region}`;
    const version = this.versions.get(subLink)?.version;
    return this.networking.getGraphRenewables(subLink, version).pipe(
      map((response) =>
        response && response.status === 'OK' && !!response.data
          ? response
          : this.versions.get(subLink)
      ),
      tap((response) => this.versions.set(subLink, response)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  public buildEnsembleSpreads(
    data: RenewablesGraphData
  ): RenewablesEnsSpread[] {
    return data?.items?.reduce(
      (acc, { model, displayName }) => {
        if (new Set(['GFS_ENS', 'ECMWF_ENS']).has(model)) {
          return acc.concat({
            value: displayName,
            label: displayName,
          });
        }

        return acc;
      },
      [this.renewablesDefaultEnsembleSpreads]
    );
  }

  public buildEnsembleSpreadDropdownOptions(
    ensembleSpreadList: RenewablesEnsSpread[]
  ): DropdownOption[] {
    return ensembleSpreadList.map(
      ({ label: labelKey, value }): DropdownOption => ({
        value,
        labelKey,
      })
    );
  }

  public buildChartOptions(
    data: RenewablesGraphData,
    graphScale: string,
    ensSpread: string,
    legendState: Record<string, boolean>,
    range: ChartRange,
    product: RenewablesProducts,
    isWidget: boolean
  ): any {
    // Init Chart Options from base
    // Set yAxis Title
    // Set xAxis Extremes
    // Add Tooltip formatter
    const series = this.buildSeries(
      data?.items,
      graphScale,
      ensSpread,
      legendState,
      product,
      isWidget
    );
    const abbreviation = graphScale === 'megawatts' ? 'Mw' : '%';
    const datePipe = this.datePipe;
    const decimalPipe = this.decimalPipe;
    const navigatorDataObject = series
      .flatMap(({ data }) => data as number[][])
      .map(([x, y]) => [Math.round(x / (1000 * 60 * 60)), y])
      .reduce((acc, [x, y]) => {
        if (acc[x.toString()] && acc[x.toString()] > y) {
          return acc;
        }
        return { ...acc, [x]: y };
      }, {} as Record<string, number>);

    const navigatorSeriesData = Object.entries(navigatorDataObject)
      .map(([key, value]) => {
        return [Number(key) * 1000 * 60 * 60, value];
      })
      .sort(([a], [b]) => {
        return a > b ? 1 : -1;
      });
    const hiddenSeriesData = navigatorSeriesData.map(([x]) => [x, null]);
    const rangeData =
      range.min && range.max && range.min < range.max
        ? {
            min: range.min,
            max: range.max,
          }
        : {};
    return {
      ...baseChartOptions,
      navigator: {
        ...baseChartOptions.navigator,
        series: {
          ...baseChartOptions.navigator.series,
          data: navigatorSeriesData,
        },
      },
      series: [
        {
          name: 'hidden',
          data: [...hiddenSeriesData],
          id: 'hidden',
          visible: true,
          showInLegend: false,
        },
        ...series,
      ],
      yAxis: [
        {
          ...baseChartOptions.yAxis[0],
          ...defaultAxisLabelsStyle,
          title: {
            ...baseChartOptions.yAxis[0].title,
            text: graphScale === 'megawatts' ? 'Megawatts' : '% of Capacity',
          },
        },
      ],
      xAxis: [
        {
          ...baseChartOptions.xAxis[0],
          ...rangeData,
          ...defaultAxisLabelsStyle,
        },
      ],
      tooltip: {
        ...baseChartOptions.tooltip,
        formatter: function (this: Highcharts.TooltipFormatterContextObject) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const tooltip = this;
          const date = new Date(tooltip['x'] as number);
          const dateMinusHour = date.setHours(date.getHours() - 1);
          const formatedDate = Highcharts.dateFormat(
            '%A, %b %d',
            dateMinusHour as number
          );

          const time = datePipe.transform(tooltip['x'], 'H', 'UTC');
          const convertedTime = time === '0' ? 24 : time;
          const day = [
            `<div style="width: 282px; color: #6C7C84; margin-top: 10px; margin-bottom: 15px; font-weight: 700; font-size: 13px;">
              <div style="text-align: center; text-transform: capitalize; margin-bottom: 5px;">${product} (${abbreviation})</div>
              <div style="text-align: center;">${formatedDate}, HE${convertedTime} ${data?.timeZone}</div>
            </div>`,
          ].join('');
          let color: string;
          const datapoints = tooltip.points
            ?.filter(({ series }) => series.type === 'spline')
            .map(({ series, y }: any, index) => {
              const isFirstInGroup = color !== series.color;
              color = series.color;
              const yValue = decimalPipe.transform(y);
              const style = {
                color: '#6C7C84',
                display: 'flex',
                'justify-content': 'space-between',
                'font-size': '12px',
                'margin-top': isFirstInGroup && index !== 0 ? '27px' : '8px',
                'margin-bottom':
                  index + 1 === tooltip.points?.length ? '20px' : 'inherit',
                'padding-left': '28px',
                'padding-right': '21px',
              };
              const isThreeDashesLineIcon = isThreeDashesLineGraphIcon(
                series.userOptions.lineIcon
              );

              return [
                `<div style='${formStyle(style)}'>
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      gap: 0.375rem;
                      padding-right: 1.25rem;
                    "
                  >
                    <span
                      style="
                        display: inline-flex;
                        width: 1.25rem;
                        height: 1.25rem;
                        margin-left: ${isThreeDashesLineIcon ? '0.125rem' : 0};
                        stroke:${series.color};
                        fill:${series.color};
                      "
                    >
                      ${series.userOptions.svgIcon}
                    </span>
                    <span
                      style="
                        display: inline-flex;
                        margin-left: ${isThreeDashesLineIcon ? '-0.125rem' : 0};
                      "
                    >
                      ${series.name}
                    </span>
                  </div>
                  <div style="font-weight: bold; line-height: 17px">
                    ${yValue}
                  </div>
                </div>`,
              ].join('');
            })
            .join('');

          return [day, datapoints].join('');
        },
      },
    };
  }

  private transformResponse(
    response: ServerResponse<RenewablesResponse>,
    subLink: string
  ): ServerResponse<RenewablesData> {
    if (response && response.status === 'OK') {
      return {
        ...response,
        data: {
          ...response.data,
          initTime: response.data.init_Time,
          localInitTime: response.data.local_Init_Time,
        },
      };
    }
    return this.versions.get(subLink);
  }

  private cacheVersion(
    subLink: string,
    response: ServerResponse<RenewablesData>
  ): void {
    if (response) {
      this.versions.set(subLink, response);
    }
  }

  private handleError(): Observable<ServerResponse<RenewablesData>> {
    return of({
      status: 'OK',
      version: '1',
      data: {
        minDate: '',
        maxDate: '',
        region: '',
        model: '',
        initTime: '',
        localInitTime: '',
        timeZone: '',
        windForecastDataSet: [],
        solarForecastDataSet: [],
        totalForecastDataSet: [],
      },
    });
  }

  private buildSeries(
    allItems: RenewablesGraphItem[],
    graphScale: string,
    ensSpread: string,
    legendState: Record<string, boolean>,
    product: RenewablesProducts,
    isWidget: boolean
  ): CustomSeries[] {
    const series: CustomSeries[] = [];
    const items = allItems?.filter(
      ({ model }) =>
        !seriesToSkip.some((skipModel) => model.includes(skipModel))
    );
    for (let i = 0, len = items?.length; i < len; i++) {
      const item = items[i];
      const isExistedItem = !!series.find(({ id }) => item.modelRun === id);
      if (!item.data[product] || isExistedItem) {
        continue;
      }
      const seriesModelRunDef = seriesModelRunMap[item.model];
      const seriesModelRunOrderDef =
        item.model === 'OBS' ? {} : seriesModelRunOrderMap[item.modelRunOrder];
      const offset = true; // offset data by one hour to correct hour beginning vs. hour ending

      // build guide for forecast / forecast mean
      const field = graphScale === 'megawatts' ? 'value' : 'percentCapacity';
      const data = this.buildData(item.data[product], field, offset);
      const isVisible = Object.entries(legendState).find(([key]) =>
        item.modelRun.includes(key)
      )?.[1];
      const defaultLegendState = isWidget
        ? startEurWidgetLegendState
        : startLegendState;
      const isVisibleByDefault = Object.entries(defaultLegendState).find(
        ([key]) => item.modelRun.includes(key)
      )?.[1];
      const visible = isVisible ?? isVisibleByDefault;
      const seriesGuide = {
        ...baseSeriesTemplate,
        ...seriesModelRunDef,
        ...seriesModelRunOrderDef,
        showInNavigator: false,
        name: item.displayName,
        data,
        modelRunOrder: item.modelRunOrder,
        id: item.modelRun,
        visible: visible && data.length > 0,
        complete: item.complete,
        showInLegend: true,
      };
      series.push(seriesGuide);

      const ensModelsMap = {
        GFS_ENS: {
          color95: 'rbga(255,177,94,0.2)',
          fillColor95: 'rgba(255,177,94,0.2)',
          color75: 'rgba(255,177,94,1.0)',
          fillColor75: 'rgba(255,177,94,0.4)',
        },
        ECMWF_ENS: {
          color95: 'rgba(250,3,0,0.2)',
          fillColor95: 'rgba(250,3,0,0.2)',
          color75: 'rgba(250,3,0,0.4)',
          fillColor75: 'rgba(250,3,0,0.2)',
        },
      };

      // build guide for percentiles
      if (new Set(['GFS_ENS', 'ECMWF_ENS']).has(item.model)) {
        const { color95, fillColor95, color75, fillColor75 } =
          ensModelsMap[item.model as 'GFS_ENS' | 'ECMWF_ENS'];

        const template = Object.assign(
          {},
          baseSeriesTemplate,
          baseBandTemplate,
          seriesModelRunDef
        );

        const guide75 = this.buildConfidenceBand(
          template,
          item,
          ensSpread,
          graphScale === 'megawatts' ? 'megawatts' : 'percentCapacity',
          graphScale === 'megawatts' ? 'forecast_25th' : 'percentCapacity25th',
          graphScale === 'megawatts' ? 'forecast_75th' : 'percentCapacity75th',
          { fillColor: fillColor75, color: color75 },
          offset,
          product
        );

        const guide95 = this.buildConfidenceBand(
          template,
          item,
          ensSpread,
          graphScale === 'megawatts' ? 'megawatts' : 'percentCapacity',
          graphScale === 'megawatts' ? 'forecast_5th' : 'percentCapacity5th',
          graphScale === 'megawatts' ? 'forecast_95th' : 'percentCapacity95th',
          { fillColor: fillColor95, color: color95 },
          offset,
          product
        );
        if (guide95) {
          series.push(guide95);
        }
        if (guide75) {
          series.push(guide75);
        }
      }
    }

    return series;
  }

  private buildConfidenceBand(
    template: CustomSeries,
    item: RenewablesGraphItem,
    ensSpread: string,
    graphScaleMatch: string,
    minField: keyof RenewableProductGraph,
    maxField: keyof RenewableProductGraph,
    color: any, // Record<'color', string>,
    offset: boolean,
    product: RenewablesProducts
  ): CustomSeries {
    const data = this.buildBandData(
      item.data[product],
      minField,
      maxField,
      offset
    );
    return {
      ...template,
      ...color,
      name: `${item.displayName}${maxField}`,
      data,
      id: `${item.displayName}-${graphScaleMatch}-${maxField}`,
      typeField: maxField,
      visible: ensSpread === item.displayName && Boolean(data.length),
      showInLegend: false,
    };
  }

  private buildBandData(
    items: RenewableProductGraph[],
    minField: keyof RenewableProductGraph,
    maxField: keyof RenewableProductGraph,
    offset: boolean
  ): Array<[number, number | null | string, number | null | string]> {
    return items.map((item) => {
      const localValidTime = this.getDate(item.localValidTime, offset);
      const min = item[minField];
      const max = item[maxField];

      return [localValidTime, min, max];
    });
  }
  getMaxCapacity() {
    return this.maxCapacity;
  }
  setMaxCapacity(newMaxCapacity: string) {
    this.maxCapacity = newMaxCapacity;
  }

  private buildData(
    items: RenewableProductGraph[],
    field: keyof RenewableProductGraph,
    offset: boolean
  ): Array<[number, number | null | string]> {
    return items.map((item: RenewableProductGraph) => {
      const localValidTime = this.getDate(item.localValidTime, offset);
      const value: any = item[field];
      return [localValidTime, Math.round(value)];
    });
  }

  private getDate(date: string, offset: boolean) {
    return offset
      ? new Date(date).valueOf() + 60 * 60 * 1000
      : new Date(date).valueOf();
  }

  public getTableRunDates(): Observable<RenewablesRunDates[]> {
    return this.networking
      .getTableRunDatesRenewables()
      .pipe(map(({ data }) => data));
  }

  public getTableRenewables(
    params: TableProps
  ): Observable<ServerResponse<RenewablesData>> {
    const baseUrl = 'api/v1/renewables/renewables-table';
    return this.networking.getTableRenewables(baseUrl, params).pipe(
      map((response) => this.transformResponse(response, baseUrl)),
      catchError(this.handleError)
    );
  }
}

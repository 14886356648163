import { SiteNameOverralAggregates } from '@firebird-web/shared-interfaces';

export const normalsDropDownList = [
  {
    labelKey: 'thirtyYearAverageVS',
    value: 'AvgYrThirty',
  },
  {
    labelKey: 'tenYearAverageVS',
    value: 'AvgYrTen',
  },
  {
    labelKey: 'priorOneYearAverageVS',
    value: 'LastYear',
  },
];

export const normalsGraphDropDownList = [
  {
    labelKey: 'thirtyYearAverage',
    value: 'AvgYrThirty',
  },
  {
    labelKey: 'tenYearAverage',
    value: 'AvgYrTen',
  },
  {
    labelKey: 'priorOneYearAverage',
    value: 'LastYear',
  },
];

export const normalsIsoCountryGraphDropDownList = [
  {
    labelKey: 'thirtyYearAverageNormals',
    value: 'AvgYrThirty',
  },
  {
    labelKey: 'tenYearAverageNormals',
    value: 'AvgYrTen',
  },
  {
    labelKey: 'priorOneYearAverage',
    value: 'LastYear',
  },
];

export const widgetSizes = [
  { labelKey: 'Full Width', value: 'full' },
  { labelKey: 'Half Width', value: 'half' },
];
export const modelForecastList = [
  {
    labelKey: 'minMaxTemp',
  },
  {
    labelKey: 'populationWeightedDd',
  },
];

export const modelSingleRunDropdownList = [
  { labelKey: 'modelRunTime', value: 'modelRunTime' },
  { labelKey: 'singleModel', value: 'singleModel' },
];

export const modelForecastDetailsDropdown = [
  {
    labelKey: 'temperatureDailySummary',
    value: 'temperatureDailySummary',
  },
  {
    labelKey: 'temperatureDetails',
    value: 'temperatureDetails',
  },
  {
    labelKey: 'cloudCoverDetails',
    value: 'cloudCoverDetails',
  },
  {
    labelKey: 'windSpeedDetails',
    value: 'windSpeedDetails',
  },
  {
    labelKey: 'precipitationDetails',
    value: 'precipitationDetails',
  },
];

export const modelForecastDdDetailsNaDropdown = [
  {
    labelKey: 'Gas Weighted HDD',
    value: 'gasWeightedHdd',
  },
  {
    labelKey: 'Pop Weighted CDD',
    value: 'populationWeightedCdd',
  },
  {
    labelKey: 'Electric Weighted CDD',
    value: 'electricWeightedCdd',
  },
  {
    labelKey: 'Oil Weighted HDD',
    value: 'oilWeightedHdd',
  },
  {
    labelKey: 'Electric Weighted HDD',
    value: 'electricWeightedHdd',
  },
  {
    labelKey: 'Pop Weighted HDD',
    value: 'popWeightedHdd',
  },
  {
    labelKey: 'temperatureDailySummary',
    value: 'temperatureDailySummary',
  },
  {
    labelKey: 'temperatureDetails',
    value: 'temperatureDetails',
  },
  {
    labelKey: 'cloudCoverDetails',
    value: 'cloudCoverDetails',
  },
  {
    labelKey: 'windSpeedDetails',
    value: 'windSpeedDetails',
  },
  {
    labelKey: 'precipitationDetails',
    value: 'precipitationDetails',
  },
];

export const modelForecastDdDetailsEurDropdown = [
  {
    labelKey: 'Pop Weighted HDD',
    value: 'popWeightedHdd',
  },
  {
    labelKey: 'Pop Weighted CDD',
    value: 'populationWeightedCdd',
  },
  {
    labelKey: 'temperatureDailySummary',
    value: 'temperatureDailySummary',
  },
  {
    labelKey: 'temperatureDetails',
    value: 'temperatureDetails',
  },
  {
    labelKey: 'cloudCoverDetails',
    value: 'cloudCoverDetails',
  },
  {
    labelKey: 'windSpeedDetails',
    value: 'windSpeedDetails',
  },
  {
    labelKey: 'precipitationDetails',
    value: 'precipitationDetails',
  },
];

export const wddFilterMap = {
  gasWeightedHdd: 'Gas Weighted HDD',
  populationWeightedCdd: 'Pop Weighted CDD',
  electricWeightedCdd: 'Electric Weighted CDD',
  oilWeightedHdd: 'Oil Weighted HDD',
  electricWeightedHdd: 'Electric Weighted HDD',
  popWeightedHdd: 'Pop Weighted HDD',
};

export const OverralSiteNameAggregates: SiteNameOverralAggregates = {
  conus: {
    name: 'CONUS',
    value: 'Conus',
  },
  overall: {
    name: 'Overall',
    value: 'Overall',
  },
  europe: {
    name: 'Europe Aggregate',
    value: 'Europe (agg)',
  },
  asia: {
    name: 'Asia Aggregate',
    value: 'Asia (agg)',
  },
  mexico: {
    name: 'Mexico Aggregate',
    value: 'Mexico (agg)',
  },
};

export const OverralSiteNameList = [
  OverralSiteNameAggregates.overall.value,
  OverralSiteNameAggregates.conus.value,
  OverralSiteNameAggregates.europe.value,
  OverralSiteNameAggregates.asia.value,
  OverralSiteNameAggregates.mexico.value,
];

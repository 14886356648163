import { WidgetSizes } from '../../constants';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DashboardService } from '../../services/dashboard.service';
import {
  ModelConfig,
  changeSinglePanelConfigs,
  getSinglePanelConfigs,
  loadSinglePanelConfigs,
  loadSinglePanelRuns,
} from '@firebird-web/model-store';
import { Store } from '@ngrx/store';
import { ISingleModelWidgetData, WidgetTypes } from '../../constants';
import { WidgetService } from '../../services/widget.service';
import { WidgetApiService } from '../../services/widget-api.service';
import { first } from 'rxjs';

@Component({
  selector: 'firebird-web-model-panel',
  templateUrl: './model-panel.component.html',
  styleUrls: ['./model-panel.component.scss'],
})
export class ModelPanelComponent implements OnInit {
  selectedSize: WidgetSizes = WidgetSizes.half;
  filterConfigs$ = this.store.select(getSinglePanelConfigs);
  widgetId: string;
  modelConfig?: ModelConfig | null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ISingleModelWidgetData,
    private dialogRef: MatDialogRef<ModelPanelComponent>,
    private dashboard: DashboardService,
    private widgetApiService: WidgetApiService,
    private widgetServie: WidgetService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.widgetId = this.data?.widgetId;
    this.loadWidgetConfigs();
  }

  loadWidgetConfigs() {
    if (this.data.isNew) {
      this.store.dispatch(loadSinglePanelConfigs());
    } else {
      const selectedConfig: ModelConfig = this.data.widgetConfig;
      this.store.dispatch(changeSinglePanelConfigs({ selectedConfig }));
      this.store.dispatch(loadSinglePanelConfigs()); //means widget is being edited;
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  filterChange(selectedConfig: ModelConfig) {
    this.widgetApiService
      .getMostUpToDateModelRun(
        selectedConfig.model.model,
        selectedConfig.parameter.id,
        selectedConfig.model.period
      )
      .pipe(first())
      .subscribe((run) => (selectedConfig.run = run));
    this.store.dispatch(
      changeSinglePanelConfigs({
        selectedConfig,
      })
    );
    if (!selectedConfig.run && selectedConfig.parameter) {
      this.store.dispatch(loadSinglePanelRuns());
      this.modelConfig = null;
      return;
    }

    this.modelConfig = selectedConfig;
  }

  onSave() {
    if (!this.modelConfig) {
      return;
    }
    const widgetModelConfig = {
      w: 4,
      h: 33,
      ...this.modelConfig,
      model: {
        group: this.modelConfig.model.group,
        model: this.modelConfig.model.model,
        period: this.modelConfig.model.period,
        id: this.modelConfig.model.id,
        title: this.modelConfig.model.title,
        intervals: this.modelConfig?.model?.intervals ?? [],
      },
      view: {
        id: this.modelConfig.view.id,
        title: this.modelConfig.view.title,
      },
    };

    if (this.data.isNew) {
      // pull out only needed configs for the widget to not bloat the storage
      this.dashboard.createWidget(widgetModelConfig, WidgetTypes.model);
      this.widgetServie.updateWidgetById(this.widgetId, widgetModelConfig);
      this.dashboard.saveDashboard();
      this.dialogRef.close();
    } else {
      this.widgetServie.updateWidgetById(this.data.widgetId, widgetModelConfig);
      this.filterChange(this.modelConfig);
      this.dashboard.saveDashboard();
      this.dialogRef.close();
    }
  }
}

export enum WeightedTabKey {
  Iso = 'iso-country-forecast',
  Degree = 'weighted-degree-forecast',
  GasDemand = 'us-gas-demand-forecast',
}

export enum WeightedTabValue {
  'iso-country-forecast' = 'iso',
  'weighted-degree-forecast' = 'degree',
  'us-gas-demand-forecast' = 'gasDemand',
}

export enum WeightedHeatMapSymbols {
  MP_MODEL_NOT_PERMITTED = '--/--',
  MP_MODEL_FIRST_COL_NOT_PERMITTED = 'AG2 MF',
  MP_MODEL_DIFFERENCE_NOT_PERMITTED = '--',
}

export enum WeightedToggles {
  TABLE = 'Table',
  GRAPH = 'Graph',
  TABLE_AND_GRAPH = 'Table and Graph',
}

export enum WeightedPrimaryLensType {
  AG2 = 'ag2Forecast',
  Model = 'modelForecast',
}

export enum WeightedTableType {
  Daily = 'daily',
  Period = 'period',
}

export enum IsoNaRegionType {
  Iso = 'isoType',
  Eia = 'eiaType',
}

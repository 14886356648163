import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { IsoCountryForecastService } from 'libs/weighted/src/lib/modules/iso-forecast-container/services/iso-country-forecast.service';
import {
  EurEiaWeightedWidgetLocations,
  IWeightedGraphPanelData,
  SUMMARY_TABLE_WIDGET_DEFAULT_CONTINENT,
  WEIGHTED_GRAPH_WIDGET_DEFAULT_EUR_REGION,
  WEIGHTED_GRAPH_WIDGET_DEFAULT_NA_EIA_REGION,
  WEIGHTED_GRAPH_WIDGET_DEFAULT_NA_ISO_REGION,
  WEIGHTED_GRAPH_WIDGET_EUR_CONTINENT,
  WidgetTypes,
} from '../../constants';
import { filter, first, tap } from 'rxjs';
import {
  modelForecastDdDetailsEurDropdown,
  modelForecastDdDetailsNaDropdown,
  widgetSizes,
} from '@firebird-web/shared-constants';
import {
  EnsembleSpread,
  IWeightedModelParams,
  WeightedForecastGraph,
} from 'libs/weighted/src/lib/interfaces/weighted-model-forecast.interface';
import { DashboardService } from '../../services/dashboard.service';
import { WeightedLocationSelectionComponent } from 'libs/shared/components/src/lib/weighted-location-selection/weighted-location-selection.component';
import { WidgetService } from '../../services/widget.service';
import { WidgetPanelService } from '../../services';
import { DropdownOption } from '../../../../../shared/interfaces/src/lib';
import { MatSelectChange } from '@angular/material/select';
import { WEIGHTED_WIDGET_PANEL_NO_ENSEMBLE_SPREAD_DROPDOWN_OPTION } from '../../constants/widget-defaults';
import { modelForecastDetailsDropdown } from '@firebird-web/shared-constants';
import {
  IsoNaRegionType,
  WeightedTabKey,
} from '../../../../../weighted/src/lib/enums';
import { IOption } from '../../../../../shared/ui/src/lib/components/dropdown/dropdown.component';
import { NaIsoRegionTypeDataSource } from '../../../../../weighted/src/lib/constants/weighted.constants';
import { NaWidgetRegionTypeDataSource } from '../../../../../weighted/src/lib/interfaces';
import { PermissionsService } from '../../../../../acl/src/lib/permissions.service';
import { first as lodashFirst } from 'lodash';

@Component({
  selector: 'firebird-web-weighted-graph-panel',
  templateUrl: './weighted-graph-panel.component.html',
  styleUrls: ['./weighted-graph-panel.component.scss'],
})
export class WeightedGraphPanelComponent implements OnInit {
  modelWeightedForecastOptions: IOption[];
  selectedContinent: string;
  selectedSiteId: string;
  biasCorrect: boolean;
  modelWeightedForecast: string;
  ensembleSpreads: DropdownOption[] = [];
  selectedSize: string;
  chartData: WeightedForecastGraph;
  selectedEnsembleSpread!: DropdownOption | undefined;
  labels = ['Location', 'Parameter'];
  widgetSizes = widgetSizes;
  modelWeightedForecastParams: IWeightedModelParams;
  legendsState: any;

  private regionTypeDataSource: IOption[] = [];
  private selectedRegionType: IsoNaRegionType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IWeightedGraphPanelData,
    private matDialog: MatDialog,
    private isoCountryService: IsoCountryForecastService,
    private dialogRef: MatDialogRef<WeightedGraphPanelComponent>,
    private dashboardService: DashboardService,
    private widgetService: WidgetService,
    private widgetPanelService: WidgetPanelService,
    private readonly permissionsService: PermissionsService
  ) {}

  get isPrecip() {
    return this.modelWeightedForecast === 'precipitationDetails';
  }

  ngOnInit(): void {
    this.createRegionTypeDataSourceWithPermission();
    this.modelWeightedForecast = this.data.widgetConfig?.modelWeightedForecast;
    if (this.data.isNew) {
      this.configureDefault();
      this.updateLocationBasedOnState();
    } else {
      this.handleEditState();
    }

    this.getGraphData();
  }

  public ensSpreadChange({ value }: MatSelectChange): void {
    this.selectedEnsembleSpread = { labelKey: value, value };
  }

  detailsChanged(event: any) {
    this.modelWeightedForecast = event;
  }

  toggleBias({ target: { checked } }: any) {
    this.biasCorrect = checked;
  }

  configureDefault() {
    const unlockedRegionsTypes = this.regionTypeDataSource.filter(
      (regionType) => {
        return regionType.isPermitted;
      }
    );

    this.selectedContinent = this.getDefaultContinent;
    this.selectedRegionType =
      (lodashFirst(unlockedRegionsTypes)?.value as IsoNaRegionType) ||
      IsoNaRegionType.Iso;
    this.selectedSiteId = this.getDefaultSiteId();
    this.setModelWeightedForecastDataSource(
      this.getDefaultContinent,
      this.selectedSiteId
    );

    this.modelWeightedForecastParams = {
      continent: this.getDefaultContinent,
      siteId: this.selectedSiteId,
      isBiasCorrected: true,
      secondaryLens: 'AvgYrThirty',
      tempUnit: 'F',
      precipUnit: 'in',
      windUnit: 'mph',
    };

    this.selectedSize = 'full';
    this.biasCorrect = true;
    this.modelWeightedForecast = 'temperatureDailySummary';
  }

  handleEditState() {
    this.modelWeightedForecastParams = {
      continent: this.data.widgetConfig.continent,
      siteId: this.data.widgetConfig.siteId,
      isBiasCorrected: this.data.widgetConfig.isBiasCorrected,
      secondaryLens: 'AvgYrThirty',
      tempUnit: 'F',
      precipUnit: 'in',
      windUnit: 'mph',
      isPrecip: this.isPrecip,
    };
    this.selectedRegionType = this.data.widgetConfig.selectedRegionType;
    this.legendsState = { ...this.data.widgetConfig?.chartLegends };

    const chartType = this.getChartType();

    this.setModelWeightedForecastDataSource(
      this.data.widgetConfig.continent,
      this.data?.widgetConfig?.siteId
    );
    this.isoCountryService
      .getModelWeightedForecastGraph(
        this.modelWeightedForecastParams,
        chartType
      )
      .pipe(
        tap((resp: any) => {
          const ensembleSpreads: EnsembleSpread[] = resp?.ensembleSpreads ?? [];

          this.generateEnsembleSpreadsDropdownOptions(ensembleSpreads);
          this.defineSelectedEnsembleSpread(ensembleSpreads);
          this.selectedSiteId = resp.siteId;
          this.selectedContinent = this.data.widgetConfig.continent;
          this.biasCorrect = this.data.widgetConfig.isBiasCorrected;
        })
      )
      .subscribe();
  }

  onSave() {
    const chartDataParams = {
      continent: this.selectedContinent,
      siteId: this.selectedSiteId,
      isBiasCorrected: this.biasCorrect,
      modelWeightedForecast: this.modelWeightedForecast,
      chartSpread: this.selectedEnsembleSpread,
      weightedContinent: this.selectedContinent,
      weightedRegion: this.selectedSiteId,
      size: this.selectedSize === 'full' ? 4 : 2,
      type: WidgetTypes.weightedGraph,
      selectedRegionType: this.selectedRegionType,
    };

    if (this.data.isNew) {
      this.dashboardService.createWidget(
        chartDataParams,
        WidgetTypes.weightedGraph
      );
      this.widgetService.updateWidgetLocationState({
        type: WidgetTypes.weightedGraph,
        continent: this.selectedContinent,
        region: this.selectedSiteId,
      });
      this.dialogRef.close();
      this.dashboardService.saveDashboard();
    } else {
      this.dialogRef.close({
        ...chartDataParams,
        chartLegends: this.legendsState,
      });
    }
  }

  openWeightedLocationSelector() {
    this.matDialog
      .open(WeightedLocationSelectionComponent, {
        width: '500px',
        minHeight: '300px',
        maxHeight: '90vh',
        data: {
          filter: 'iso',
          selectedRegionType: this.selectedRegionType,
          regionTypeDataSource: this.regionTypeDataSource,
          isWidget: true,
          continentId:
            this.data.widgetConfig.continent || this.getDefaultContinent,
          continentList: [
            { id: 'NA', text: 'North America' },
            { id: 'EUR', text: 'Europe' },
          ],
        },
      })
      .afterClosed()
      .pipe(
        first(),
        filter((resp: any) => resp),
        tap((resp: any) => {
          this.selectedRegionType = resp?.selectedRegionType;
          this.selectedContinent = resp?.continentId;
          this.selectedSiteId = resp?.regionId;
          this.setModelWeightedForecastDataSource(
            this.selectedContinent,
            this.selectedSiteId
          );

          const hasModelForecastInDataSources =
            this.modelWeightedForecastOptions.some((option) => {
              return option.value === this.modelWeightedForecast;
            });

          if (!hasModelForecastInDataSources) {
            this.modelWeightedForecast =
              lodashFirst(this.modelWeightedForecastOptions)?.value || '';
          }
        })
      )
      .subscribe();
  }

  getContinent(contId: string): string {
    return contId === 'NA' ? 'North America' : 'Europe';
  }

  onClose() {
    this.dialogRef.close();
  }
  private get getDefaultContinent(): string {
    return this.widgetService.getDefaultContinentId();
  }
  updateLocationBasedOnState() {
    const { continent, region } = this.widgetService.widgetLocationState || {
      continent: '',
      region: '',
    };
    if (this.widgetService.isSameWidgetType(WidgetTypes.weightedGraph)) {
      this.updateLocationForSameWidgetType(continent, region || '');
    } else {
      this.updateLocationForDifferentWidgetType(continent, region || '');
    }
  }

  private updateLocationForSameWidgetType(continent: string, region: string) {
    this.selectedContinent = continent;
    this.selectedSiteId = region;
  }

  private getGraphData(): void {
    const chartType = this.getChartType();

    this.isoCountryService
      .getModelWeightedForecastGraph(
        this.modelWeightedForecastParams,
        chartType
      )
      .pipe(
        first(),
        tap((resp: any) => {
          const ensembleSpreads: EnsembleSpread[] = resp?.ensembleSpreads ?? [];

          this.generateEnsembleSpreadsDropdownOptions(ensembleSpreads);
          this.defineSelectedEnsembleSpread(ensembleSpreads);
        })
      )
      .subscribe();
  }

  private updateLocationForDifferentWidgetType(
    continent: string,
    region: string
  ) {
    if (!['NA', 'EUR'].includes(continent)) return;

    this.selectedContinent = continent;
    this.getWeightedRegionsByContinent(continent)
      .pipe(first())
      .subscribe((regions) => {
        const newRegion = this.getNewRegion(regions, region);
        this.selectedSiteId = newRegion;
      });
  }

  getNewRegion(regions: any, region: string) {
    const foundRegion = regions.find((reg: any) => reg.text === region);
    return foundRegion ? foundRegion.id : regions[0].id;
  }
  getWeightedRegionsByContinent(continent: string) {
    return this.widgetPanelService.getWeightedRegions(
      continent,
      'iso-country-forecast'
    );
  }

  private generateEnsembleSpreadsDropdownOptions(
    ensembleSpreads: EnsembleSpread[]
  ): void {
    this.ensembleSpreads = [
      WEIGHTED_WIDGET_PANEL_NO_ENSEMBLE_SPREAD_DROPDOWN_OPTION,
      ...ensembleSpreads.map(
        ({ displayName }): DropdownOption => ({
          labelKey: displayName,
          value: displayName,
        })
      ),
    ];
  }

  private createRegionTypeDataSourceWithPermission(): void {
    this.regionTypeDataSource = NaIsoRegionTypeDataSource.map(
      (regionType: NaWidgetRegionTypeDataSource) => {
        const isPermitted = regionType?.permission
          ? this.permissionsService.isPermitted(regionType?.permission)
          : false;

        return {
          ...regionType,
          isPermitted,
        };
      }
    );
  }

  private defineSelectedEnsembleSpread(
    ensembleSpreads: EnsembleSpread[]
  ): void {
    let chartSpread: DropdownOption | undefined =
      this.data?.widgetConfig?.chartSpread;

    if (
      !chartSpread ||
      !ensembleSpreads
        .map(({ displayName }) => displayName)
        .includes(chartSpread.value)
    ) {
      chartSpread = WEIGHTED_WIDGET_PANEL_NO_ENSEMBLE_SPREAD_DROPDOWN_OPTION;
    }

    this.selectedEnsembleSpread = chartSpread;
  }

  private getDefaultSiteId(): string {
    if (this.getDefaultContinent !== SUMMARY_TABLE_WIDGET_DEFAULT_CONTINENT) {
      return WEIGHTED_GRAPH_WIDGET_DEFAULT_EUR_REGION;
    }

    return this.selectedRegionType === IsoNaRegionType.Iso
      ? WEIGHTED_GRAPH_WIDGET_DEFAULT_NA_ISO_REGION
      : WEIGHTED_GRAPH_WIDGET_DEFAULT_NA_EIA_REGION;
  }

  private getChartType(): WeightedTabKey {
    return this.selectedRegionType === IsoNaRegionType.Eia
      ? WeightedTabKey.Degree
      : WeightedTabKey.Iso;
  }

  private setModelWeightedForecastDataSource(
    continent: string,
    siteId: string
  ): void {
    if (continent === SUMMARY_TABLE_WIDGET_DEFAULT_CONTINENT) {
      this.modelWeightedForecastOptions =
        this.selectedRegionType === IsoNaRegionType.Eia
          ? modelForecastDdDetailsNaDropdown
          : modelForecastDetailsDropdown;
      return;
    }

    this.modelWeightedForecastOptions =
      continent === WEIGHTED_GRAPH_WIDGET_EUR_CONTINENT &&
      EurEiaWeightedWidgetLocations.includes(siteId)
        ? modelForecastDdDetailsEurDropdown
        : modelForecastDetailsDropdown;
  }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocationService } from 'libs/shared/services/src/lib/location.service';
import {
  filterDropDownValues,
  primaryLensAvgTempDropdown,
  primaryLensPrecipDropdown,
  primaryLensDropdown,
  sumTableSizes,
  CUSTOM_LIST,
  SUMMARY_TABLE_WIDGET_CUSTOM_LIST_CONTINENT,
  SUMMARY_TABLE_WIDGET_DEFAULT_REGION,
  WidgetTypes,
  IWidgetLocationState,
} from '../../constants';
import { WidgetService } from '../../services/widget.service';
import {
  filter,
  first,
  BehaviorSubject,
  Subject,
  switchMap,
  takeUntil,
  map,
  Observable,
} from 'rxjs';
import { IWidgetSummaryPanelData } from '../../constants';
import { DashboardService } from '../../services/dashboard.service';
import { WidgetPanelService } from '../../services';
import {
  DataSource,
  DecimalToggleEvent,
} from '../../../../../shared/interfaces/src/lib';
import { Store } from '@ngrx/store';
import { getAllUnits } from '@firebird-web/user-settings-store';
import { extractLocationSelectionDialogResult } from '../../../../../shared/components/src/lib/location-selection/utils';
import { LocationSelectionDialogResult } from '../../../../../shared/components/src/lib/location-selection/location.interface';
import { RunDatesEntity } from '@firebird-web/run-dates-store';
import {
  getLatestRunDate,
  getLatestRunDateAndCompareRunDate,
  getLatestRunDateStorageTime,
  isPrimaryLensForecastDifference,
  isSameRunDates,
} from '../../../../../forecast/src/lib/modules/overview/helpers/overview-lens.helper';
import { MatSelectChange } from '@angular/material/select';
import { PrimaryLensEntity } from '@firebird-web/primary-lens-store';
import { DashboardDataService } from '../../services/data/dashboard/dashboard-data.service';
import {
  RegionsEntity,
  getAllRegions,
  loadRegions,
} from '@firebird-web/regions-store';
import { LocationPickerCustomList } from '@firebird-web/location-picker-store';

@Component({
  selector: 'firebird-web-heat-map-panel',
  templateUrl: './heat-map-panel.component.html',
  styleUrls: ['./heat-map-panel.component.scss'],
})
export class HeatMapPanelComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IWidgetSummaryPanelData,
    private locService: LocationService,
    private dialogRef: MatDialogRef<HeatMapPanelComponent>,
    private widgetService: WidgetService,
    private store: Store,
    private dashboardDataService: DashboardDataService,
    private dashboardService: DashboardService,
    private widgetPanelService: WidgetPanelService
  ) {}
  filterDropDownValues = filterDropDownValues.map(
    (filter: { value: string; labelKey: string }) => ({
      ...filter,
      id: filter.value,
      label: filter.labelKey,
    })
  );
  secondaryLensValues: DataSource[];
  sumTableSizes = sumTableSizes;
  summaryTableContinents = ['MEX', 'EUR', 'NA', 'ASIA'];
  regions!: RegionsEntity[];
  primLensValues!: any;
  selectedFilter!: string;
  selectedPrimLens!: string;
  primLensAndPermsisions: PrimaryLensEntity;
  showDecimal = false;
  units: { tempUnit: string; windUnit: string; precipUnit: string };
  selectedContinent!: string;
  forecastType!: string;
  selectedSumTableSize = 'full';
  widgetId!: string;
  selectedRegion!: string;
  selectedSecondaryLens!: string;
  widgetType!: string;
  private widgetLocation: IWidgetLocationState | null = null;
  private customListId!: number | undefined;
  private runDate!: string;
  private compareRunDate!: string;
  private storageTime!: string;
  private runDates: RunDatesEntity[] = [];
  private continent$ = new BehaviorSubject<string>('');
  private destroy$ = new Subject<boolean>();

  public ngOnInit(): void {
    this.dashboardDataService
      .getPrimaryLensList()
      .pipe(
        first(),
        filter((primaryLens) => !!primaryLens)
      )
      .subscribe((primaryLens) => {
        this.primLensAndPermsisions = primaryLens;
      });
    this.configureDefault(this.data.isNew);
    this.getLatestForecastRunDate();
    this.updateWidgetLocationBasedOnType();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.continent$.complete();
  }

  public changeFilter({ value }: MatSelectChange): void {
    this.selectedFilter = value;
    this.forecastType = this.getCorrectForecastType(value);
    // prevents frisk index being selected when something other than average temp filter is selected
    this.selectedPrimLens = this.getCorrectPrimLens()[0]?.value;
  }
  getCorrectPrimLens() {
    if (this.selectedFilter === 'AverageTemp') {
      return this.getCorrectPrimaryLensDropdown('AverageTemp');
    }
    if (this.selectedFilter === 'Max Dewpoint') {
      return this.getCorrectPrimaryLensDropdown('MaxDewpoint');
    }
    return this.getCorrectPrimaryLensDropdown(this.selectedFilter);
  }
  primLensChange(event: any) {
    this.selectedPrimLens = event.value;
  }

  showDecimalCheckbox(): boolean {
    return (
      this.units.tempUnit === 'C' && !this.isFrisk && !this.isPrecipForecast
    );
  }
  sizeChange(event: any) {
    this.selectedSumTableSize = event.value;
  }

  getCorrectPrimaryLensDropdown(filter: string) {
    const noDfnOptions = ['DegreeDays', 'MinMaxFeelsLike', 'MaxDewpoint'];
    if (filter === 'AverageTemp') {
      return this.addPermissionToPrimaryLens(primaryLensAvgTempDropdown);
    }
    if (filter === 'precipAmount' || filter === 'probabilityOfPrecip') {
      return this.addPermissionToPrimaryLens(primaryLensPrecipDropdown);
    }
    if (noDfnOptions.includes(filter)) {
      return this.addPermissionToPrimaryLens(
        primaryLensDropdown.filter(
          (item) => item.value !== 'DepartureFromNorms'
        )
      );
    }
    return this.addPermissionToPrimaryLens(primaryLensDropdown);
  }
  addPermissionToPrimaryLens(
    primLensList: {
      labelKey: string;
      value: string;
    }[]
  ) {
    return primLensList.map((primLens) => {
      const isPermitted = this.primLensAndPermsisions?.primaryLens.some(
        (permittedItem) =>
          permittedItem.id === primLens.value && permittedItem.isPermitted
      );
      return { ...primLens, isPermitted };
    });
  }
  toggleDecimal(event: Event): void {
    const { checked } = event.target as DecimalToggleEvent;
    this.showDecimal = checked;
  }

  public onSave(): void {
    const {
      continent: savedContinent = '',
      region = '',
      customList = '',
    } = this.widgetLocation ?? ({} as IWidgetLocationState);
    const continent = customList
      ? SUMMARY_TABLE_WIDGET_CUSTOM_LIST_CONTINENT
      : savedContinent;

    if (this.data.isNew) {
      this.dashboardService.createWidget(
        {
          primaryLens: this.selectedPrimLens,
          secondaryLens: this.selectedSecondaryLens,
          filter: this.selectedFilter,
          runDates: this.runDates,
          runDate: this.runDate,
          compareRunDate: this.compareRunDate,
          storageTime: this.storageTime,
          customListId: this.customListId,
          continent,
          showDecimal: this.showDecimal,
          region: this.selectedRegion,
          h: 30,
          w: 8,
          forecastType: this.getCorrectForecastType(this.selectedFilter),
          precipUnit: this.units.precipUnit,
          windUnit: this.units.windUnit,
          tempUnit: this.units.tempUnit,
        },
        WidgetTypes.heatMap
      );
      this.widgetService.updateWidgetLocationState(
        this.widgetLocation ?? ({} as IWidgetLocationState)
      );
    }

    return this.dialogRef.close({
      primaryLens: this.selectedPrimLens,
      secondaryLens: this.selectedSecondaryLens,
      filter: this.selectedFilter,
      customListId: this.customListId,
      runDates: this.runDates,
      runDate: this.runDate,
      storageTime: this.storageTime,
      showDecimal: this.showDecimal,
      compareRunDate: this.compareRunDate,
      continent,
      region,
      forecastType: this.getCorrectForecastType(this.selectedFilter),
    });
  }

  private configureDefault(isNew: boolean): void {
    this.store
      .select(getAllUnits)
      .pipe(first())
      .subscribe(({ tempUnit, precipUnit, windUnit }) => {
        this.units = {
          tempUnit,
          precipUnit,
          windUnit,
        };
      });
    this.secondaryLensValues =
      this.widgetPanelService.adaptSecondaryLensDataSource();

    if (isNew) {
      this.selectedContinent = this.getDefaultContinent;
      this.selectedRegion = SUMMARY_TABLE_WIDGET_DEFAULT_REGION;
      this.selectedFilter = 'MinMax';
      this.selectedPrimLens = 'Forecast';
      this.selectedSecondaryLens = 'AvgYrThirty';
      this.forecastType = 'temperature';
      this.widgetType = 'heat-map';
      this.showDecimal = false;
      this.setWidgetLocation(
        this.getDefaultContinent,
        SUMMARY_TABLE_WIDGET_DEFAULT_REGION
      );
    } else {
      this.selectedContinent = this.data.widgetConfig?.customListId
        ? CUSTOM_LIST
        : this.data.widgetConfig.continent;
      this.setWidgetLocation(
        this.data.widgetConfig.continent,
        this.data.widgetConfig.region
      );
      this.selectedFilter = this.data.widgetConfig?.filter;
      this.selectedRegion = this.data.widgetConfig?.region;
      this.forecastType = this.data.widgetConfig.forecastType;
      this.runDates = this.data.widgetConfig?.runDates;
      this.runDate = this.data.widgetConfig?.runDate;
      this.compareRunDate = this.data.widgetConfig?.compareRunDate;
      this.storageTime = this.data.widgetConfig?.storageTime;
      this.selectedPrimLens = this.data.widgetConfig?.primaryLens;
      this.selectedSecondaryLens = this.data.widgetConfig?.secondaryLens;
      this.showDecimal = this.data.widgetConfig.showDecimal;
      this.widgetType = this.data.widgetType;
      this.customListId = Number(this.data.widgetConfig?.customListId);
    }

    this.continent$.next(this.selectedContinent);
  }

  getContinentById(contId: string): Observable<string> {
    return this.locService.findContinentByIdAsync(contId).pipe(
      map((continentName) => {
        return contId === 'Custom List' ? CUSTOM_LIST : continentName;
      })
    );
  }

  onClose() {
    this.dialogRef.close();
  }
  isSameType() {
    return (
      this.widgetService.widgetLocationState !== null &&
      this.widgetService.widgetLocationState.type === 'heat-map'
    );
  }

  private updateWidgetLocationBasedOnType(): void {
    if (!this.widgetService.widgetLocationState || !this.data.isNew) {
      return;
    }

    const { continent, region, customList } =
      this.widgetService.widgetLocationState;

    if (this.isSameType()) {
      this.updateLocationForSameWidgetType(continent, region, customList);
    } else {
      this.updateLocationForDifferentWidgetType(continent, region);
    }
  }

  private updateLocationForSameWidgetType(
    savedContinent: string,
    savedRegion?: string,
    customList?: LocationPickerCustomList
  ): void {
    const continent = savedContinent || this.getDefaultContinent;
    const region = savedRegion || 'All Regions';

    this.selectedContinent = customList ? CUSTOM_LIST : continent;
    this.selectedRegion = customList ? customList?.name : region;
    this.customListId = Number(customList?.id);
    this.setWidgetLocation(continent, region, customList);
    this.continent$.next(customList ? CUSTOM_LIST : continent);
  }

  private updateLocationForDifferentWidgetType(
    continent: string,
    region: string | undefined
  ): void {
    if (!this.summaryTableContinents.includes(continent)) {
      return;
    }
    this.getRegionsBasedOnContinent(continent);
    const newRegion = this.getRegionName(region);
    this.selectedContinent = continent;
    this.selectedRegion = newRegion;
    this.setWidgetLocation(continent, newRegion);
    this.continent$.next(continent);
  }

  private getRegionName(region: string | undefined): string {
    const foundRegion = this.regions.find((reg) => reg.regionName === region);
    return foundRegion ? foundRegion.regionName : 'All Regions';
  }
  secondaryLensChange(event: any) {
    this.selectedSecondaryLens = event.value;
  }

  public locationChange(): void {
    if (this.widgetType === 'heat-map') {
      this.locService
        .openWidgetLocationSelection(this.continent)
        .pipe(
          first(),
          filter((result): result is LocationSelectionDialogResult => !!result),
          map((result) => extractLocationSelectionDialogResult(result))
        )
        .subscribe(
          ({
            isCustomList,
            customList = undefined,
            continent: { id: continent = '' },
            region,
          }) => {
            if (isCustomList) {
              const { name: customListName, id: customListId } =
                customList as LocationPickerCustomList;

              this.selectedContinent = CUSTOM_LIST;
              this.selectedRegion = customListName;
              this.customListId = Number(customListId);
              this.continent$.next(SUMMARY_TABLE_WIDGET_CUSTOM_LIST_CONTINENT);
            } else {
              this.selectedContinent = continent;
              this.selectedRegion = region;
              this.customListId = undefined;
              this.continent$.next(continent);
            }

            this.setWidgetLocation(continent, region, customList);
          }
        );
    }
  }

  private setWidgetLocation(
    continent: string,
    region: string,
    customList?: LocationPickerCustomList
  ): void {
    this.widgetLocation = {
      type: WidgetTypes.heatMap,
      continent,
      region,
      ...(customList && { customList }),
    };
  }

  private getCorrectForecastType(filter: string): string {
    return ['precipAmount', 'probabilityOfPrecip'].includes(filter)
      ? 'precipitation'
      : 'temperature';
  }

  private getLatestForecastRunDate(): void {
    this.continent$
      .pipe(
        switchMap((continent) =>
          this.widgetService.getRunDatesValue(continent)
        ),
        filter(
          (runDates) =>
            !isSameRunDates(getLatestRunDate(runDates), this.runDate)
        ),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (runDates) => {
          const [runDate, compareRunDate] =
            getLatestRunDateAndCompareRunDate(runDates);

          this.runDates = runDates;
          this.runDate = runDate;
          this.compareRunDate = compareRunDate;
          this.storageTime = getLatestRunDateStorageTime(runDates);
        },
      });
  }

  private get getDefaultContinent(): string {
    return this.widgetService.getDefaultContinentId();
  }

  public get isForecastDifference(): boolean {
    return isPrimaryLensForecastDifference(this.selectedPrimLens);
  }

  public get isFrisk(): boolean {
    return this.selectedPrimLens === 'FriskIndex';
  }

  public get isPrecipForecast(): boolean {
    return this.forecastType === 'precipitation';
  }

  public get continent(): string {
    return this.continent$.getValue();
  }
  public getRegionsBasedOnContinent(continent: string) {
    this.store.dispatch(
      loadRegions({
        selectedContinentId: continent,
      })
    );
    this.store
      .select(getAllRegions)
      .pipe(first())
      .subscribe((regions) => {
        this.regions = regions;
      });
  }
}

import { NaRenewablesChartState } from '../types';
import { NaChartSeriesModelRun } from '../enums';

export const DefaultNaChartState: NaRenewablesChartState = {
  scale: 'megawatts',
  chartType: 'wind',
  range: {
    label: '24 Hrs',
    value: {
      min: 0,
      max: 1,
    },
  },
};

export const DefaultLocationLegendState = {
  [NaChartSeriesModelRun.AG2_LATEST]: true,
  [NaChartSeriesModelRun.AG2_PREVIOUS]: false,
  [NaChartSeriesModelRun.AG2_PRIMARY]: true,
  [NaChartSeriesModelRun.AG2_EVENING]: false,
  [NaChartSeriesModelRun.AG2_PRIMARY_YESTERDAY]: true,
  [NaChartSeriesModelRun.AG2_EVENING_YESTERDAY]: false,
  [NaChartSeriesModelRun.LOCATION_MORNING]: true,
  [NaChartSeriesModelRun.LOCATION_LATEST]: true,
  [NaChartSeriesModelRun.LOCATION_PREVIOUS]: false,
  [NaChartSeriesModelRun.LOCATION_PREVIOUS_MORNING]: true,
  [NaChartSeriesModelRun.OBS]: true,
  [NaChartSeriesModelRun.FARM_CAPACITY]: false,
  [NaChartSeriesModelRun.CONFIDENCE_BANDS]: false,
  [NaChartSeriesModelRun.AG2_LATEST_NET]: true,
  [NaChartSeriesModelRun.AG2_MORNING_NET]: true,
  [NaChartSeriesModelRun.AG2_PREVIOUS_MORNING_NET]: false,
  [NaChartSeriesModelRun.LOCATION_LATEST_NET]: true,
  [NaChartSeriesModelRun.LOCATION_MORNING_NET]: true,
  [NaChartSeriesModelRun.LOCATION_PREVIOUS_MORNING_NET]: false,
};

export const DefaultErcotLegendState = {
  [NaChartSeriesModelRun.AG2_LATEST]: true,
  [NaChartSeriesModelRun.AG2_PREVIOUS]: false,
  [NaChartSeriesModelRun.AG2_PRIMARY]: true,
  [NaChartSeriesModelRun.AG2_EVENING]: false,
  [NaChartSeriesModelRun.AG2_PRIMARY_YESTERDAY]: true,
  [NaChartSeriesModelRun.AG2_EVENING_YESTERDAY]: false,
  [NaChartSeriesModelRun.ERCOT_WGRPP_LATEST_MORNING]: true,
  [NaChartSeriesModelRun.ERCOT_STWPF_LATEST_MORNING]: false,
  [NaChartSeriesModelRun.ERCOT_WGRPP_LATEST]: true,
  [NaChartSeriesModelRun.ERCOT_STWPF_LATEST]: false,
  [NaChartSeriesModelRun.ERCOT_WGRPP_PREVIOUS]: false,
  [NaChartSeriesModelRun.ERCOT_STWPF_PREVIOUS]: false,
  [NaChartSeriesModelRun.ERCOT_WGRPP_MORNING]: true,
  [NaChartSeriesModelRun.ERCOT_STWPF_MORNING]: false,
  [NaChartSeriesModelRun.OBS]: true,
  [NaChartSeriesModelRun.FARM_CAPACITY]: false,
  [NaChartSeriesModelRun.CONFIDENCE_BANDS]: false,
  [NaChartSeriesModelRun.AG2_LATEST_NET]: true,
  [NaChartSeriesModelRun.AG2_MORNING_NET]: true,
  [NaChartSeriesModelRun.AG2_PREVIOUS_MORNING_NET]: false,
  [NaChartSeriesModelRun.LOCATION_LATEST_NET]: true,
  [NaChartSeriesModelRun.LOCATION_MORNING_NET]: true,
  [NaChartSeriesModelRun.LOCATION_PREVIOUS_MORNING_NET]: false,
};

import { ModelConfig } from '@firebird-web/model-store';
import {
  RenewablesEnsSpread,
  RenewablesProducts,
} from 'libs/renewable/src/lib/models/renewables.models';
import {
  EnsembleSpread,
  WeightedForecastGraph,
} from 'libs/weighted/src/lib/interfaces/weighted-model-forecast.interface';
import { RunDatesEntity } from '@firebird-web/run-dates-store';
import { IRangeSelectorOption } from '../../../../renewable/src/lib/models';
import { SubZone } from '../interfaces';
import { LocationPickerCustomList } from '@firebird-web/location-picker-store';

export enum WidgetTypes {
  heatMap = 'heat-map',
  interactiveMap = 'interactive-map',
  weatherTimeline = 'weather-timeline',
  compMatrix = 'comp-matrix',
  renewables = 'renewables',
  naRenewables = 'na-renewables',
  model = 'model',
  weightedGraph = 'weighted-graph',
  analysis = 'analysis',
  weighted = 'weighted',
  demand = 'demand',
}

export interface ISummaryWidgetConfig {
  compareRunDate: string;
  compareToDate?: { initTime: string; type: string };
  continent: string;
  continentId: string;
  primaryLensId: string;
  customListId: string;
  forecastType: string;
  duration: string;
  filter: string;
  listName: string;
  precipUnit: string;
  primaryLens: string;
  region: string;
  dailyPeriod: string;
  runDate: string;
  runDates: RunDatesEntity[];
  storageTime: string;
  secondaryLens: string;
  size: number;
  tempUnit: string;
  type: WidgetTypes;
  isNew?: boolean;
  layers?: string[];
  mapLayers?: string[];
  showRoadsAndPlaces: boolean;
  siteId: string;
  widgetId: string;
  windUnit: string;
  currentDate: string;
  city: string;
  showDifference: boolean;
  chartLegends: any;
  showDecimal: boolean;
  showNormal: boolean;
  biasCorrected: boolean;
  modelForecast: string;
  runTime: string;
  modelRun: string;
  subzone: SubZone;
  shouldReload: boolean;
  isUpdateEngine?: boolean;
  scale?: string;
  legend?: Record<string, boolean>;
  poolId?: string;
  pool?: string;
  isAggregate?: boolean;
  product?: RenewablesProducts;
  range?: {
    label: string;
    value: {
      min: number;
      max: number;
    };
  };
}

export interface IWidgetLocationState {
  type: string;
  continent: string;
  region?: string;
  siteId?: string;
  customList?: LocationPickerCustomList;
}

export type WidgetTypeList =
  | WidgetTypes.heatMap
  | WidgetTypes.renewables
  | WidgetTypes.analysis
  | WidgetTypes.weightedGraph
  | WidgetTypes.weighted;

export type WidgetConfigType =
  | ISummaryWidgetConfig
  | IWeightedModelGraphWidgetConfig;

export interface IWeightedModelGraphWidgetConfig {
  ensSpread: { label: string; value: string };
  ensembleSpreads: EnsembleSpread[];
  continent: string;

  chartData: WeightedForecastGraph;
  showBiasCorrected: boolean;
  siteId: string;
  size: number;
  modelWeightedForecast: string;
  type: string;
}

export interface IRenewableWidgetConfig {
  continent: string;
  ensSpread: RenewablesEnsSpread;
  ensSpreads: RenewablesEnsSpread[];
  product: RenewablesProducts;
  region: string;
  scale: string;
  size: number;
  pool: string;
  poolId: string;
  isAggregate: boolean;
  legend: Record<string, boolean>;
  range: IRangeSelectorOption;
  type: WidgetTypes.renewables | WidgetTypes.naRenewables;
}
export interface IWidgetSummaryPanelData {
  widgetType: WidgetTypeList;
  widgetConfig: ISummaryWidgetConfig;
  widgetId: string;
  isNew: boolean;
}
export interface IWidgetLocationData {
  continent: string;
  region: string;
  isCustom: string;
  listName: string;
  name: string;
  siteId: string;
}

export interface IWidgetWeightedLocationData {
  regionName: string;
  regionId: string;
  continentId: string;
}

export interface IRenewableWidgetPanelData {
  widgetType: WidgetTypes.renewables;
  widgetConfig: IRenewableWidgetConfig;
  widgetId: string;
  isNew: boolean;
}

export interface IWeightedGraphPanelData {
  widgetType: WidgetTypes.weightedGraph;
  widgetConfig: any; //to be changed
  widgetId: string;
  isNew: boolean;
}
export interface ISingleModelWidgetData {
  widgetType: WidgetTypes.model;
  widgetConfig: ModelConfig; // needs to be changed
  widgetId: string;
  isNew: boolean;
}
export interface ISumTableConfigOptions {
  text: string;
  id: string;
}
export interface IImapDefault {
  isNew: boolean;
  layers: string[];
  showRoadsAndPlaces: boolean;
  mapLayers: string[];
}

import { MultiplePermissions, SinglePermission } from '../types';

// The Renewables tab and route to the Renewables module
export const RENEWABLE_TAB_MULTIPLE_PERMISSIONS: MultiplePermissions = [
  ['Renewables', 'EUR', 'Global_Models'],
  ['Renewables', 'EUR', 'GRAF'],
  ['Renewables', 'NA', 'Wind', 'CAISO'],
  ['Renewables', 'NA', 'Wind', 'ERCOT'],
  ['Renewables', 'NA', 'Wind', 'MISO'],
  ['Renewables', 'NA', 'Wind', 'NEISO'],
  ['Renewables', 'NA', 'Wind', 'NYISO'],
  ['Renewables', 'NA', 'Wind', 'PJM'],
  ['Renewables', 'NA', 'Wind', 'SPP'],
  ['Renewables', 'NA', 'Wind', 'BPA'],
  ['Renewables', 'NA', 'Solar', 'ERCOT'],
  ['Renewables', 'NA', 'Solar', 'CAISO'],
  ['Wind_Graphics_80m', 'NA_East'],
  ['Wind_Graphics_80m', 'NA_West'],
  ['Wind_Graphics_80m', 'EUR'],
  ['Wind_Graphics_80m', 'MEX'],
];

// The Table toggle on the Renewables page
export const RENEWABLES_TABLE_MULTIPLE_PERMISSIONS: MultiplePermissions = [
  ['Renewables', 'EUR', 'Global_Models'],
  ['Renewables', 'EUR', 'GRAF'],
  ['Renewables', 'NA', 'Wind', 'CAISO'],
  ['Renewables', 'NA', 'Wind', 'ERCOT'],
  ['Renewables', 'NA', 'Wind', 'MISO'],
  ['Renewables', 'NA', 'Wind', 'NEISO'],
  ['Renewables', 'NA', 'Wind', 'NYISO'],
  ['Renewables', 'NA', 'Wind', 'PJM'],
  ['Renewables', 'NA', 'Wind', 'SPP'],
  ['Renewables', 'NA', 'Wind', 'BPA'],
  ['Renewables', 'NA', 'Solar', 'ERCOT'],
  ['Renewables', 'NA', 'Solar', 'CAISO'],
];

// The route to the Renewables Table (EUR) module
export const RENEWABLES_TABLE_EUR_MULTIPLE_PERMISSIONS: MultiplePermissions = [
  ['Renewables', 'EUR', 'Global_Models'],
  ['Renewables', 'EUR', 'GRAF'],
];

// The route to the Renewables Table (NA) module
export const RENEWABLES_TABLE_NA_MULTIPLE_PERMISSIONS: MultiplePermissions = [
  ['Renewables', 'NA', 'Wind', 'CAISO'],
  ['Renewables', 'NA', 'Wind', 'ERCOT'],
  ['Renewables', 'NA', 'Wind', 'MISO'],
  ['Renewables', 'NA', 'Wind', 'NEISO'],
  ['Renewables', 'NA', 'Wind', 'NYISO'],
  ['Renewables', 'NA', 'Wind', 'PJM'],
  ['Renewables', 'NA', 'Wind', 'SPP'],
  ['Renewables', 'NA', 'Wind', 'BPA'],
  ['Renewables', 'NA', 'Solar', 'ERCOT'],
  ['Renewables', 'NA', 'Solar', 'CAISO'],
];

// The route to the Renewables Maps module and the Maps toggle on the Renewables page
export const RENEWABLES_MAPS_MULTIPLE_PERMISSIONS: MultiplePermissions = [
  ['Wind_Graphics_80m', 'NA_East'],
  ['Wind_Graphics_80m', 'NA_West'],
  ['Wind_Graphics_80m', 'EUR'],
  ['Wind_Graphics_80m', 'MEX'],
  ['Renewables', 'NA', 'Wind', 'CAISO'],
  ['Renewables', 'NA', 'Wind', 'ERCOT'],
  ['Renewables', 'NA', 'Wind', 'MISO'],
  ['Renewables', 'NA', 'Wind', 'NEISO'],
  ['Renewables', 'NA', 'Wind', 'NYISO'],
  ['Renewables', 'NA', 'Wind', 'PJM'],
  ['Renewables', 'NA', 'Wind', 'SPP'],
  ['Renewables', 'NA', 'Wind', 'BPA'],
  ['Renewables', 'NA', 'Solar', 'ERCOT'],
  ['Renewables', 'NA', 'Solar', 'CAISO'],
];

// The EUR continent in the continent picker dialog on the Renewables Maps page
export const RENEWABLES_MAPS_EUR_CONTINENT_PERMISSION: SinglePermission = [
  'Wind_Graphics_80m',
  'EUR',
];

// The MEX continent in the continent picker dialog on the Renewables Maps page
export const RENEWABLES_MAPS_MEX_CONTINENT_PERMISSION: SinglePermission = [
  'Wind_Graphics_80m',
  'MEX',
];

// The CONUS region of the NA continent in the continent picker dialog on the Renewables Maps page
export const RENEWABLES_MAPS_NA_CONUS_REGION_MULTIPLE_PERMISSIONS: MultiplePermissions =
  [
    ['Wind_Graphics_80m', 'NA_East'],
    ['Wind_Graphics_80m', 'NA_West'],
    ['Renewables', 'NA', 'Wind', 'CAISO'],
    ['Renewables', 'NA', 'Wind', 'ERCOT'],
    ['Renewables', 'NA', 'Wind', 'MISO'],
    ['Renewables', 'NA', 'Wind', 'NEISO'],
    ['Renewables', 'NA', 'Wind', 'NYISO'],
    ['Renewables', 'NA', 'Wind', 'PJM'],
    ['Renewables', 'NA', 'Wind', 'SPP'],
    ['Renewables', 'NA', 'Wind', 'BPA'],
    ['Renewables', 'NA', 'Solar', 'ERCOT'],
    ['Renewables', 'NA', 'Solar', 'CAISO'],
  ];

// The NORTHEAST region of the NA continent in the continent picker dialog on the Renewables Maps page
export const RENEWABLES_MAPS_NA_NORTHEAST_REGION_MULTIPLE_PERMISSIONS: MultiplePermissions =
  [
    ['Wind_Graphics_80m', 'NA_East'],
    ['Renewables', 'NA', 'Wind', 'NEISO'],
    ['Renewables', 'NA', 'Wind', 'NYISO'],
    ['Renewables', 'NA', 'Wind', 'PJM'],
  ];

// The MISO region of the NA continent in the continent picker dialog on the Renewables Maps page
export const RENEWABLES_MAPS_NA_MISO_REGION_MULTIPLE_PERMISSIONS: MultiplePermissions =
  [
    ['Wind_Graphics_80m', 'NA_East'],
    ['Wind_Graphics_80m', 'NA_West'],
    ['Renewables', 'NA', 'Wind', 'MISO'],
  ];

// The MISO_NIOWA region of the NA continent in the continent picker dialog on the Renewables Maps page
export const RENEWABLES_MAPS_NA_MISO_NIOWA_REGION_MULTIPLE_PERMISSIONS: MultiplePermissions =
  [
    ['Wind_Graphics_80m', 'NA_East'],
    ['Wind_Graphics_80m', 'NA_West'],
    ['Renewables', 'NA', 'Wind', 'MISO'],
  ];

// The ERCOT region of the NA continent in the continent picker dialog on the Renewables Maps page
export const RENEWABLES_MAPS_NA_ERCOT_REGION_MULTIPLE_PERMISSIONS: MultiplePermissions =
  [
    ['Wind_Graphics_80m', 'NA_East'],
    ['Wind_Graphics_80m', 'NA_West'],
    ['Renewables', 'NA', 'Wind', 'ERCOT'],
    ['Renewables', 'NA', 'Solar', 'ERCOT'],
  ];

// The ERCOT_WEST_TEXAS region of the NA continent in the continent picker dialog on the Renewables Maps page
export const RENEWABLES_MAPS_NA_ERCOT_WEST_TEXAS_REGION_MULTIPLE_PERMISSIONS: MultiplePermissions =
  [
    ['Wind_Graphics_80m', 'NA_East'],
    ['Wind_Graphics_80m', 'NA_West'],
    ['Renewables', 'NA', 'Wind', 'ERCOT'],
  ];

// The NORTHWEST region of the NA continent in the continent picker dialog on the Renewables Maps page
export const RENEWABLES_MAPS_NA_NORTHWEST_REGION_MULTIPLE_PERMISSIONS: MultiplePermissions =
  [
    ['Wind_Graphics_80m', 'NA_West'],
    ['Renewables', 'NA', 'Wind', 'BPA'],
  ];

// The CAISO region of the NA continent in the continent picker dialog on the Renewables Maps page
export const RENEWABLES_MAPS_NA_CAISO_REGION_MULTIPLE_PERMISSIONS: MultiplePermissions =
  [
    ['Wind_Graphics_80m', 'NA_West'],
    ['Renewables', 'NA', 'Wind', 'CAISO'],
    ['Renewables', 'NA', 'Solar', 'CAISO'],
  ];

// The SPP region of the NA continent in the continent picker dialog on the Renewables Maps page
export const RENEWABLES_MAPS_NA_SPP_REGION_MULTIPLE_PERMISSIONS: MultiplePermissions =
  [
    ['Wind_Graphics_80m', 'NA_East'],
    ['Wind_Graphics_80m', 'NA_West'],
    ['Renewables', 'NA', 'Wind', 'SPP'],
  ];

// The SOUTHWEST region of the NA continent in the continent picker dialog on the Renewables Maps page
export const RENEWABLES_MAPS_NA_SOUTHWEST_REGION_PERMISSION: SinglePermission =
  ['Wind_Graphics_80m', 'NA_West'];

// The Windspeed option in the dropdown on the Renewables Maps page for the NA continent
export const RENEWABLES_MAPS_NA_WIND_FORECAST_TYPE_MULTIPLE_PERMISSIONS: MultiplePermissions =
  [
    ['Wind_Graphics_80m', 'NA_East'],
    ['Wind_Graphics_80m', 'NA_West'],
    ['Renewables', 'NA', 'Wind', 'CAISO'],
    ['Renewables', 'NA', 'Wind', 'ERCOT'],
    ['Renewables', 'NA', 'Wind', 'MISO'],
    ['Renewables', 'NA', 'Wind', 'NEISO'],
    ['Renewables', 'NA', 'Wind', 'NYISO'],
    ['Renewables', 'NA', 'Wind', 'PJM'],
    ['Renewables', 'NA', 'Wind', 'SPP'],
    ['Renewables', 'NA', 'Wind', 'BPA'],
  ];

// The Solar Irradiance option in the dropdown on the Renewables Maps page for the NA continent
export const RENEWABLES_MAPS_NA_SOLAR_FORECAST_TYPE_MULTIPLE_PERMISSIONS: MultiplePermissions =
  [
    ['Renewables', 'NA', 'Solar', 'ERCOT'],
    ['Renewables', 'NA', 'Solar', 'CAISO'],
  ];

// The Hub Height button group on the Renewables Maps page for the NA continent
export const RENEWABLES_MAPS_NA_HUB_HEIGHT_MULTIPLE_PERMISSIONS: MultiplePermissions =
  [
    ['Renewables', 'NA', 'Wind', 'CAISO'],
    ['Renewables', 'NA', 'Wind', 'ERCOT'],
    ['Renewables', 'NA', 'Wind', 'MISO'],
    ['Renewables', 'NA', 'Wind', 'NEISO'],
    ['Renewables', 'NA', 'Wind', 'NYISO'],
    ['Renewables', 'NA', 'Wind', 'PJM'],
    ['Renewables', 'NA', 'Wind', 'SPP'],
    ['Renewables', 'NA', 'Wind', 'BPA'],
  ];

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { ISummaryWidgetConfig, IWidgetLocationState } from '../constants';
import { WidgetApiService } from './widget-api.service';
import { RunDatesEntity } from '@firebird-web/run-dates-store';
import { PermissionsService } from 'libs/acl/src/lib/permissions.service';
import { Store } from '@ngrx/store';
import { LocationPickerFacade } from '@firebird-web/location-picker-store';

@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  private widgetParamsMap = new Map<string, BehaviorSubject<any>>();
  private widgetId!: string;
  private defaultContinent!: string;

  public widgetLocationParams!: IWidgetLocationState;

  constructor(
    private widgetApiService: WidgetApiService,
    private store: Store,
    private locationPickerFacade: LocationPickerFacade,
    private permissionService: PermissionsService
  ) {}

  public getCurrentWidgetParmaeter() {
    return this.widgetParamsMap;
  }

  public getWidgetParameterObj() {
    return this.widgetParamsMap.get(this.widgetId)?.value;
  }

  public updateWidgetById(
    id: string,
    paramsObject: any,
    shouldReload = true
  ): void {
    if (!this.widgetParamsMap.has(id)) {
      this.widgetParamsMap.set(id, new BehaviorSubject<any>(null));
    }

    this.widgetId = id;
    const paramsSubject = this.widgetParamsMap.get(id);
    paramsSubject?.next({ ...paramsObject, shouldReload });
  }

  public getWidgetParamsChange(
    id: string
  ): BehaviorSubject<ISummaryWidgetConfig> {
    const existingValue = this.widgetParamsMap.get(id);
    if (existingValue) {
      return existingValue;
    }
    const newBehaviorSubject = new BehaviorSubject<any>(null);
    this.widgetParamsMap.set(id, newBehaviorSubject);
    return newBehaviorSubject;
  }

  public getWidgetParamMapChange(id: string): Observable<any> {
    return this.getWidgetParamsChange(id).pipe(
      map((data) => new Map(Object.entries(data)))
    );
  }

  public getWidgetId() {
    return this.widgetId;
  }

  getWidgetLocationById(id: string) {
    const widgetLocation = this.widgetParamsMap.get(id);
    if (widgetLocation) {
      return widgetLocation;
    }
    const locationBehaviorSubject = new BehaviorSubject<any>(null);
    this.widgetParamsMap.set(id, locationBehaviorSubject);
    return locationBehaviorSubject;
  }

  getWidgetShowDecimalById(id: string) {
    return this.getWidgetParamsChange(id).value.showDecimal;
  }

  getTempDetailsLabel(tempDetailValue: string): string {
    switch (tempDetailValue) {
      case 'temperatureDailySummary':
        return 'Daily Temp';
      case 'temperatureDetails':
        return '6hrly Temp';
      case 'cloudCoverDetails':
        return '6hrly Cloud Cover';
      case 'windSpeedDetails':
        return '6hrly Wind Speed';
      case 'precipitationDetails':
        return '6hrly Precip';
      case 'gasWeightedHdd':
        return 'GWHDD';
      case 'populationWeightedCdd':
        return 'PWCDD';
      case 'electricWeightedCdd':
        return 'EWCDD';
      case 'oilWeightedHdd':
        return 'OWHDD';
      case 'electricWeightedHdd':
        return 'EWHDD';
      case 'popWeightedHdd':
        return 'PWHDD';
      default:
        return 'Temperature Daily Summary';
    }
  }

  public getRunDatesValue(continent: string): Observable<RunDatesEntity[]> {
    return this.widgetApiService.getRunDates(continent).pipe(
      map((runDates) =>
        runDates.map((runDate) => {
          const { initTime } = runDate;

          return {
            ...runDate,
            id: initTime,
            label: initTime,
          } as RunDatesEntity;
        })
      )
    );
  }

  getDefaultContinent() {
    const pools = this.permissionService.getPermissions().PoolSet;
    const firstKey = Object.keys(pools)[0];
    const firstValue = pools[firstKey];
    if (firstKey.includes('NA') && firstValue) {
      this.defaultContinent = 'NA';
    }
    if (firstKey.includes('EUR') && firstValue) {
      this.defaultContinent = 'EUR';
    }
    if (firstKey.includes('ASIA') && firstValue) {
      this.defaultContinent = 'ASIA';
    }
    if (firstKey.includes('MEX') && firstValue) {
      this.defaultContinent = 'MEX';
    }
  }

  generateAnalysisWidgetDefaultLocation() {
    switch (this.defaultContinent) {
      case 'EUR':
        return {
          continent: 'Europe',
          region: 'BRITISH ISLES',
          city: 'Aberdeen',
          siteId: 'EGPD',
        };
      case 'NA':
        return {
          continent: 'North America',
          siteId: 'MISO',
          region: 'MISO',
          city: 'MISO',
        };
      case 'ASIA':
        return {
          continent: 'Asia',
          siteId: 'CHINA',
          region: 'CHINA',
          city: 'CHINA',
        };
      default:
        return {
          continent: 'North America',
          siteId: 'MISO',
          region: 'MISO',
          city: 'MISO',
        };
    }
    //TODO: add default for other continents
  }

  generateWeightedWidgetDefaultLocation() {
    switch (this.defaultContinent) {
      case 'EUR':
        return { continent: 'Europe', region: 'AUSTRIA' };
      case 'NA':
        return { continent: 'North America', region: 'MISO' };
      default:
        return { continent: 'North America', region: 'MISO' };
    }
    //TODO: add default for other continents
  }

  public updateWidgetLocationState(loc: IWidgetLocationState) {
    this.widgetLocationParams = loc;
  }

  public get widgetLocationState() {
    return this.widgetLocationParams;
  }

  public getDefaultContinentId() {
    return this.defaultContinent;
  }

  isSameWidgetType(type: string) {
    return this.widgetLocationState && this.widgetLocationState.type === type;
  }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BehaviorSubject, map, Subject, switchMap, takeUntil } from 'rxjs';
import { WeightedRegionsService } from '@firebird-web/shared-services';
import { CommonUtils } from '@firebird-web/shared-utils';
import {
  NaIsoContinent,
  WEIGHTED_CONTINENT_PERMISSION_KEY,
} from '../../../../../weighted/src/lib/constants/weighted.constants';
import { IsoNaRegionType } from '../../../../../weighted/src/lib/enums';
import { Continent, Region } from './interfaces';

export const availableFilters = ['iso', 'degree', 'gasDemand'] as const;
export type Filters = typeof availableFilters[number];

export const weightedFilterConfigs: Record<
  Filters,
  { availableContinents: string[] }
> = {
  iso: {
    availableContinents: ['NA', 'EUR'],
  },
  degree: {
    availableContinents: ['NA', 'EUR'],
  },
  gasDemand: {
    availableContinents: ['NA'],
  },
};

@Component({
  selector: 'firebird-web-weighted-location-selection',
  templateUrl: './weighted-location-selection.component.html',
  styleUrls: ['./weighted-location-selection.component.scss'],
})
export class WeightedLocationSelectionComponent implements OnInit, OnDestroy {
  public readonly naIsoContinent = NaIsoContinent;

  public continentPermissionKey = WEIGHTED_CONTINENT_PERMISSION_KEY;
  public selectedRegionType: IsoNaRegionType;
  continentList!: Continent[];
  continentId$: BehaviorSubject<string> = new BehaviorSubject('');
  regionList$: BehaviorSubject<Region[][]> = new BehaviorSubject<Region[][]>(
    []
  );
  continentText$ = this.continentId$.pipe(
    map(
      (continentId) =>
        this.continentList.find(({ id }) => continentId === id)?.text
    )
  );
  filter: Filters = availableFilters[0];

  private regionsListSource: Region[] = [];

  private readonly destroyed$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<WeightedLocationSelectionComponent>,
    private readonly weightedRegionsServices: WeightedRegionsService
  ) {
    this.selectedRegionType =
      this.data.selectedRegionType || IsoNaRegionType.Iso;
  }

  ngOnInit(): void {
    this.initLocationData();
    this.watchForContinentChange();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  changeRegionType({ value }: { value: IsoNaRegionType }): void {
    if (value === this.selectedRegionType) {
      return;
    }

    this.selectedRegionType = value;
    const filteredRegions = [...this.regionsListSource].filter(
      (region: Region) => region.regionType === value
    );
    this.regionList$.next(CommonUtils.toChunksByColumnsQty(filteredRegions, 2));
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onContinentChange(continent: Continent) {
    this.continentId$.next(continent.id);
  }

  onRegionSelected(region: Region) {
    this.dialogRef.close({
      regionName: region.text,
      regionId: region.id,
      continentId: this.continentId$.getValue(),
      selectedRegionType: this.selectedRegionType,
    });
  }

  private initLocationData(): void {
    this.filter = this.data.filter;
    this.continentList = this.data.continentList;
    const continentId = this.data.continentId;

    this.continentId$.next(continentId);
  }

  private watchForContinentChange(): void {
    this.continentId$
      .pipe(
        switchMap((continentId) => {
          switch (this.filter) {
            case 'iso': {
              return this.weightedRegionsServices.getWeightedIsoRegions(
                continentId,
                this.data?.isWidget
              );
            }
            case 'degree': {
              return this.weightedRegionsServices.getWeightedDegreeDaysRegions(
                continentId
              );
            }
            default: {
              return this.weightedRegionsServices.getWeightedUsGasBcfRegions(
                continentId
              );
            }
          }
        }),
        map((regions) => {
          this.regionsListSource = regions;

          if (
            this.data?.isWidget &&
            this.continentId$.value === NaIsoContinent
          ) {
            return regions.filter(
              (region: Region) => region.regionType === this.selectedRegionType
            );
          }

          return regions;
        }),
        map(
          (regions) =>
            CommonUtils.toChunksByColumnsQty(regions, 2) as Region[][]
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe((regions: Region[][]) => {
        this.regionList$.next(regions);
      });
  }
}

import {
  RENEWABLES_TABLE_EUR_MULTIPLE_PERMISSIONS,
  RENEWABLES_MAPS_MULTIPLE_PERMISSIONS,
  RENEWABLES_TABLE_NA_MULTIPLE_PERMISSIONS,
} from '@firebird-web/acl';
import { RenewablesPermissionsGuardData } from '../types';
import { RenewablesRoutingSubRoutes } from '../enums';

export const SHARED_RENEWABLES_PERMISSIONS_GUARD_DATA: RenewablesPermissionsGuardData =
  {
    modulePath: 'renewables',
    permissions: [],
    subRoutes: [
      {
        path: RenewablesRoutingSubRoutes.EUR,
        permission: RENEWABLES_TABLE_EUR_MULTIPLE_PERMISSIONS,
      },
      {
        path: RenewablesRoutingSubRoutes.NA,
        permission: RENEWABLES_TABLE_NA_MULTIPLE_PERMISSIONS,
      },
      {
        path: RenewablesRoutingSubRoutes.MAPS,
        permission: RENEWABLES_MAPS_MULTIPLE_PERMISSIONS,
      },
    ],
  };

import { Injectable } from '@angular/core';
import { ForecastTypes } from '@firebird-web/shared-ui';

@Injectable({
  providedIn: 'root',
})
export class ColorBarService {
  private temperatureColorCodes: string[] = [
    '#3464a7',
    '#5791bf',
    '#9dc3db',
    '#d5e4ef',
    'transparent',
    '#f7dcca',
    '#e8a988',
    '#c76753',
    '#a32a31',
  ];
  private precipitationColorCodes: string[] = [
    '#83541e',
    '#b6843d',
    '#dac285',
    '#f4e8c6',
    '#cde9e5',
    '#92cac1',
    '#52958f',
    '#2b645e',
  ];
  private windColorCodes: string[] = [
    '#0f6a7d',
    '#2da6bf',
    '#73c7d9',
    '#bce5ee',
    '#dec2d7',
    '#cc95bf',
    '#b963a6',
    '#851d6f',
  ];
  private solarColorCodes: string[] = [
    '#4d4d4d',
    '#878787',
    '#babbba',
    '#dfe0e0',
    '#f9d4b3',
    '#f4ba7c',
    '#f19c43',
    '#ee8031',
  ];
  private loadColorCodes: string[] = [
    // TBD
    '#4d4d4d',
    '#878787',
    '#babbba',
    '#dfe0e0',
    '#f9d4b3',
    '#f4ba7c',
    '#f19c43',
    '#ee8031',
  ];
  private weightedColorCodes: string[] = [
    // TBD
    '#4d4d4d',
    '#878787',
    '#babbba',
    '#dfe0e0',
    '#f9d4b3',
    '#f4ba7c',
    '#f19c43',
    '#ee8031',
  ];

  /**
   * getColorCodes
   * @param ForecastTypes
   * @returns colorCodes as string[]
   */
  getColorCodes(type: ForecastTypes): string[] {
    switch (type) {
      case 'temperature': {
        return this.temperatureColorCodes;
      }

      case 'precipitation': {
        return this.precipitationColorCodes;
      }

      case 'wind': {
        return this.windColorCodes;
      }

      case 'solar': {
        return this.solarColorCodes;
      }

      case 'load': {
        return this.loadColorCodes;
      }

      case 'weighted': {
        return this.weightedColorCodes;
      }

      default: {
        return [];
      }
    }
  }

  /**
   * getLeftLabel
   * @param type ForecastTypes
   * @returns leftLabelKey
   */
  getLeftLabel(type: ForecastTypes): string {
    switch (type) {
      case 'temperature': {
        return 'colderThanNormal';
      }
      case 'precipitation': {
        return 'lessRain';
      } // TBD
      case 'wind': {
        return 'lessWind';
      } // TBD
      case 'solar': {
        return 'lessIrradiance';
      } // TBD
      case 'load': {
        return 'less';
      } // TBD
      case 'weighted': {
        return 'less';
      } // TBD
      default: {
        return 'less';
      } // TBD
    }
  }

  /**
   * getRightLabel
   * @param type ForecastTypes
   * @returns rightLabelKey
   */
  getRightLabel(type: ForecastTypes): string {
    switch (type) {
      case 'temperature': {
        return 'warmer';
      }
      case 'precipitation': {
        return 'more';
      } // TBD
      case 'wind': {
        return 'more';
      } // TBD
      case 'solar': {
        return 'more';
      } // TBD
      case 'load': {
        return 'more';
      } // TBD
      case 'weighted': {
        return 'more';
      } // TBD
      default: {
        return 'more';
      } // TBD
    }
  }

  /**
   * getColorCodesFromClass
   * @param classNames
   * @returns
   */
  getColorCodesFromClass(classNames: string): string {
    switch (classNames) {
      case 'DiffFromNormal': {
        return '#D9D9D9';
      }
      case 'DiffFromNormalSB': {
        return '#7B34A7';
      }
      case 'DiffFromNormalMB': {
        return '#3464A7';
      }
      case 'DiffFromNormalB': {
        return '#000000';
      }
      case 'DiffFromNormalSlightB': {
        return '#D5E4EF';
      }
      case 'DiffFromNormalSlightA': {
        return '#F7DCCA';
      }
      case 'DiffFromNormalA': {
        return '#E8A988';
      }
      case 'DiffFromNormalMA': {
        return '#C76753';
      }
      case 'DiffFromNormalSA': {
        return '#A32A31';
      }
      default: {
        return '#d5e4ef';
      }
    }
  }
}

import { NaChartSeriesModelRun } from '../enums';

export const DefaultNaColors: Record<string, string> = {
  AG2: '#000000',
  LOCATION: '#CDCCCC',
};

export const CustomNaColors: Record<string, string> = {
  [NaChartSeriesModelRun.CONFIDENCE_BANDS]: '#E2E2E2',
  [NaChartSeriesModelRun.OBS]: '#2EA330',
  [NaChartSeriesModelRun.OBS_NET]: '#2EA330',
  [NaChartSeriesModelRun.FARM_CAPACITY]: '#D8BE79',
};

export const NA_RENEWABLES_UPDATE_ENGINE_CONTINENT = 'NA';

export const NA_RENEWABLES_UPDATE_ENGINE_WIND_DATA_TABLES: string[] = [
  'windcast_hourly_fcst',
  'windcast_hourly_obs',
];

export const NA_RENEWABLES_UPDATE_ENGINE_SOLAR_DATA_TABLE =
  'hourly_fcst_solar_gen';

export const NA_RENEWABLES_UPDATE_ENGINE_MODEL = 'WindcastIQ';

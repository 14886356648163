import { MultiplePermissions, SinglePermission } from '@firebird-web/acl';

export const NEWS_FEED_BLOGS_PERMISSION: SinglePermission = [
  'Forecaster_Blogs',
];

export const NEWS_FEED_BLURBS_PERMISSION: SinglePermission = [
  'Forecaster_Blurbs',
];

export const NEWS_FEED_MULTIPLE_PERMISSIONS: MultiplePermissions = [
  NEWS_FEED_BLOGS_PERMISSION,
  NEWS_FEED_BLURBS_PERMISSION,
];

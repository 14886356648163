import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { RenewablesNetworkingService } from './renewables-networking.service';
import { PermissionsService } from 'libs/acl/src/lib/permissions.service';
import { RENEWABLES_MAPS_NA_REGIONS_PERMISSIONS_MAP } from '../constants';
import {
  Permissions,
  RENEWABLES_MAPS_EUR_CONTINENT_PERMISSION,
  RENEWABLES_MAPS_MEX_CONTINENT_PERMISSION,
  RENEWABLES_TABLE_EUR_MULTIPLE_PERMISSIONS,
} from '@firebird-web/acl';
import { ContinentPickerResult } from '../../../../continent-picker/src/lib/types';
import { LocationData, RenewableRegionData } from '../models';
import { RenewablesProduct } from '../types';
import { RenewablesMapsNaRegions } from '../modules/renewables-maps/enums';
import { RenewablesProductsEnum } from '../enums';
import { TitleCasePipe } from '@angular/common';

@Injectable()
export class RenewablesService {
  public locationChangeMessage$ =
    new BehaviorSubject<ContinentPickerResult | null>(null);
  public currentProduct$ = new BehaviorSubject<RenewablesProduct | null>(null);

  constructor(
    private readonly networking: RenewablesNetworkingService,
    private readonly permissionsService: PermissionsService,
    private readonly titleCasePipe: TitleCasePipe
  ) {}

  public prepareGetLocationsData(): Observable<
    LocationData<RenewableRegionData[]>[]
  > {
    return this.networking.getLocationsData().pipe(
      map((resp) =>
        resp.map(({ continent, regions }) => {
          return {
            continent,
            regions: regions
              .filter(({ id }) => id != 'UK')
              .map(
                ({ id, displayName, isSolarAvailable, isWindAvailable }) => ({
                  labelKey: displayName,
                  value: id,
                  isPermitted: this.isNaContinent(continent)
                    ? this.permissionsService.isPermitted([
                        'Renewables',
                        continent,
                        this.titleCasePipe.transform(
                          RenewablesProductsEnum.WIND
                        ),
                        id,
                      ]) ||
                      this.permissionsService.isPermitted([
                        'Renewables',
                        continent,
                        this.titleCasePipe.transform(
                          RenewablesProductsEnum.SOLAR
                        ),
                        id,
                      ])
                    : this.permissionsService.isPermitted(
                        RENEWABLES_TABLE_EUR_MULTIPLE_PERMISSIONS
                      ),
                  isSolarAvailable,
                  isWindAvailable,
                })
              ),
          };
        })
      )
    );
  }

  public getPreparedMapsLocations(): Observable<
    Array<LocationData<RenewableRegionData[]>>
  > {
    return this.networking.getMapsLocationsData().pipe(
      map(
        (locations): Array<LocationData<RenewableRegionData[]>> =>
          locations.map(
            ({ continent, regions }): LocationData<RenewableRegionData[]> => ({
              continent,
              regions: regions.map(
                ({
                  id: value,
                  displayName: labelKey,
                  isSolarAvailable,
                  isWindAvailable,
                }): RenewableRegionData => {
                  const permissions = (
                    {
                      NA: RENEWABLES_MAPS_NA_REGIONS_PERMISSIONS_MAP[
                        value as RenewablesMapsNaRegions
                      ],
                      EUR: RENEWABLES_MAPS_EUR_CONTINENT_PERMISSION,
                      MEX: RENEWABLES_MAPS_MEX_CONTINENT_PERMISSION,
                    } as Record<'NA' | 'EUR' | 'MEX', Permissions>
                  )[continent];

                  return {
                    labelKey,
                    value,
                    isWindAvailable,
                    isSolarAvailable,
                    isPermitted: permissions
                      ? this.permissionsService.isPermitted(permissions)
                      : false,
                  };
                }
              ),
            })
          )
      )
    );
  }

  private isNaContinent(continent: string): boolean {
    return continent === 'NA';
  }
}

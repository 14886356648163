import {
  ReplaySubject,
  Subscription,
  combineLatest,
  distinctUntilChanged,
  fromEvent,
  map,
  tap,
  switchMap,
  of,
} from 'rxjs';
import {
  Directive,
  Input,
  OnInit,
  OnDestroy,
  Renderer2,
  ElementRef,
  ViewContainerRef,
  ComponentRef,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IconComponent } from 'libs/shared/ui/src/lib/components/icon/icon.component';
import { MatDialog } from '@angular/material/dialog';
import { PermissionDialogComponent } from '../../../../shared/ui/src/lib/components/permission-dialog/permission-dialog.component';
import { PermissionsService } from '../permissions.service';
import { isEqual, isBoolean, isUndefined } from 'lodash';
import { HasViewPermissionDirectivePermissions } from '@firebird-web/acl';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hasViewPermission]',
})
export class HasViewPermissionDirective
  implements OnInit, OnChanges, OnDestroy
{
  private permissions =
    new ReplaySubject<HasViewPermissionDirectivePermissions>(1);
  private permissionsSubscription: Subscription | undefined;
  private component: ComponentRef<IconComponent> =
    this.viewContainerRef.createComponent(IconComponent);
  @Input()
  set hasViewPermission(permissions: HasViewPermissionDirectivePermissions) {
    this.permissions.next(permissions);
  }
  @Input()
  alignRight = false;
  @Input()
  key?: string;
  @Input()
  hostExtraStyles = {};
  @Input()
  iconExtraStyles = {};
  @Input() dynamicStyles = {};

  @Output()
  disableHandler = new EventEmitter();

  constructor(
    private readonly permissionsService: PermissionsService,
    private readonly elementRef: ElementRef,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly renderer: Renderer2,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.component.setInput('name', 'lock');
    this.component.setInput('width', '14px');
    this.component.setInput('height', '19px');
    if (this.alignRight) {
      this.component.location.nativeElement.style.position = 'absolute';
      this.component.location.nativeElement.style.right = '8px';
    } else {
      this.component.location.nativeElement.style.paddingLeft = '10px';
    }

    this.component.location.nativeElement.style.fill = '#EE8031';
    this.component.location.nativeElement.style.color = '#333443';

    Object.entries(this.iconExtraStyles).forEach(([key, value]) => {
      this.component.location.nativeElement.style[key] = value;
    });

    const el = this.elementRef.nativeElement;
    this.permissionsSubscription = this.checkPermissions()
      .pipe(
        switchMap((isPermitted) => {
          return combineLatest([
            of(isPermitted),
            fromEvent(el, 'click', { capture: true }),
          ]);
        })
      )
      .subscribe(([isPermitted, event]: any) => {
        if (!isPermitted) {
          event.stopPropagation();
          this.openPermissionDeniedDialog();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['dynamicStyles']?.currentValue &&
      !isEqual(
        changes['dynamicStyles']?.currentValue,
        changes['dynamicStyles']?.previousValue
      )
    ) {
      Object.entries(this.dynamicStyles)?.forEach(([key, value]) => {
        this.component.location.nativeElement.style[key] = value;
      });
    }
  }

  ngOnDestroy(): void {
    this.permissionsSubscription?.unsubscribe();
  }

  private checkPermissions() {
    return this.permissions.pipe(
      map((permissions) =>
        isBoolean(permissions)
          ? permissions
          : this.permissionsService.isPermitted(
              !isUndefined(permissions) ? permissions : [],
              this.key
            )
      ),
      distinctUntilChanged(),
      tap((isPermitted) => this.updateView(isPermitted))
    );
  }

  private updateView(isPermitted: boolean) {
    const host = this.elementRef.nativeElement;
    const initialPosition = host.style.position;
    const initialPaddingRight = host.style.position;
    this.disableHandler.emit(!isPermitted);
    if (!isPermitted) {
      host.classList.add('disabled', 'permission-denied');

      this.renderer.appendChild(host, this.component.location.nativeElement);
      if (this.alignRight) {
        host.style.position = 'relative';
        host.style.paddingRight = '16px';
      } else {
        host.style.paddingRight = initialPaddingRight;
        host.style.position = initialPosition;
      }

      Object.entries(this.hostExtraStyles).forEach(([key, value]) => {
        host.style[key] = value;
      });
      return;
    }
    Object.keys(this.hostExtraStyles).forEach((key) => {
      host.style[key] = null;
    });
    this.renderer.removeChild(host, this.component.location.nativeElement);
    host.classList.remove('disabled', 'permission-denied');
  }

  private openPermissionDeniedDialog(): void {
    this.dialog.open(PermissionDialogComponent, {
      width: '500px',
      panelClass: ['firebird-dialog-panel'],
      autoFocus: false,
    });
  }
}

import {
  RENEWABLES_MAPS_MULTIPLE_PERMISSIONS,
  RENEWABLES_TABLE_MULTIPLE_PERMISSIONS,
} from '@firebird-web/acl';
import { IToggleTableInput } from '@firebird-web/shared-ui';
import { TableMapsToggle } from '../enums';

export const toggleHeatMapActive: IToggleTableInput[] = [
  {
    labelKey: TableMapsToggle.HeatMap,
    iconName: 'layers-grid-add-1',
    permission: RENEWABLES_TABLE_MULTIPLE_PERMISSIONS,
  },
  {
    labelKey: TableMapsToggle.Maps,
    iconName: 'maps-pin-black',
    permission: RENEWABLES_MAPS_MULTIPLE_PERMISSIONS,
  },
];

export const toggleMapsActive: IToggleTableInput[] = [
  {
    labelKey: TableMapsToggle.HeatMap,
    iconName: 'layers-grid-add-1-inactive',
    permission: RENEWABLES_TABLE_MULTIPLE_PERMISSIONS,
  },
  {
    labelKey: TableMapsToggle.Maps,
    iconName: 'maps-pin',
    permission: RENEWABLES_MAPS_MULTIPLE_PERMISSIONS,
  },
];

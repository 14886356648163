import {
  Permissions,
  RENEWABLES_MAPS_NA_CAISO_REGION_MULTIPLE_PERMISSIONS,
  RENEWABLES_MAPS_NA_CONUS_REGION_MULTIPLE_PERMISSIONS,
  RENEWABLES_MAPS_NA_ERCOT_REGION_MULTIPLE_PERMISSIONS,
  RENEWABLES_MAPS_NA_ERCOT_WEST_TEXAS_REGION_MULTIPLE_PERMISSIONS,
  RENEWABLES_MAPS_NA_MISO_NIOWA_REGION_MULTIPLE_PERMISSIONS,
  RENEWABLES_MAPS_NA_MISO_REGION_MULTIPLE_PERMISSIONS,
  RENEWABLES_MAPS_NA_NORTHEAST_REGION_MULTIPLE_PERMISSIONS,
  RENEWABLES_MAPS_NA_NORTHWEST_REGION_MULTIPLE_PERMISSIONS,
  RENEWABLES_MAPS_NA_SOUTHWEST_REGION_PERMISSION,
  RENEWABLES_MAPS_NA_SPP_REGION_MULTIPLE_PERMISSIONS,
} from '@firebird-web/acl';
import { RenewablesMapsNaRegions } from '../modules/renewables-maps/enums';

export const RENEWABLES_MAPS_NA_REGIONS_PERMISSIONS_MAP: Record<
  RenewablesMapsNaRegions,
  Permissions
> = {
  [RenewablesMapsNaRegions.CONUS]:
    RENEWABLES_MAPS_NA_CONUS_REGION_MULTIPLE_PERMISSIONS,
  [RenewablesMapsNaRegions.NORTHEAST]:
    RENEWABLES_MAPS_NA_NORTHEAST_REGION_MULTIPLE_PERMISSIONS,
  [RenewablesMapsNaRegions.MISO]:
    RENEWABLES_MAPS_NA_MISO_REGION_MULTIPLE_PERMISSIONS,
  [RenewablesMapsNaRegions.MISO_NIOWA]:
    RENEWABLES_MAPS_NA_MISO_NIOWA_REGION_MULTIPLE_PERMISSIONS,
  [RenewablesMapsNaRegions.ERCOT]:
    RENEWABLES_MAPS_NA_ERCOT_REGION_MULTIPLE_PERMISSIONS,
  [RenewablesMapsNaRegions.ERCOT_WEST_TEXAS]:
    RENEWABLES_MAPS_NA_ERCOT_WEST_TEXAS_REGION_MULTIPLE_PERMISSIONS,
  [RenewablesMapsNaRegions.NORTHWEST]:
    RENEWABLES_MAPS_NA_NORTHWEST_REGION_MULTIPLE_PERMISSIONS,
  [RenewablesMapsNaRegions.CAISO]:
    RENEWABLES_MAPS_NA_CAISO_REGION_MULTIPLE_PERMISSIONS,
  [RenewablesMapsNaRegions.SPP]:
    RENEWABLES_MAPS_NA_SPP_REGION_MULTIPLE_PERMISSIONS,
  [RenewablesMapsNaRegions.SOUTHWEST]:
    RENEWABLES_MAPS_NA_SOUTHWEST_REGION_PERMISSION,
};

import {
  AnalysisWidgetDetails,
  InitDataSources,
  WeightedWidgetDetails,
  WeightedWidgetPanelState,
  WidgetPanelState,
} from '../interfaces';
import { first } from 'lodash';
import {
  DataSource,
  DropdownOption,
} from '../../../../shared/interfaces/src/lib';
import {
  CategoryDataSourceList,
  ForecastDataSourceList,
  ISummaryWidgetConfig,
  WidgetSizes,
} from '../constants';
import { WeightedPageState } from '../../../../weighted-store/src/lib/model';
import { ForecastValues, RunTimeModelValues } from '../enums';

export const buildWidgetDetails = (
  selectedContinentId: string,
  currentDate: string,
  panelState: WidgetPanelState,
  tempUnit: string
): AnalysisWidgetDetails => {
  return {
    continent: selectedContinentId,
    currentDate: currentDate,
    secondaryLens: panelState.selectedSecondaryLens,
    region: panelState.selectedRegion,
    siteId: panelState.selectedSiteId,
    city: panelState.selectedCity,
    showDecimal: panelState.showDecimalValue,
    showDifference: panelState.diffPriorValue,
    tempUnit: tempUnit,
    size: getTableSize(panelState.selectedSumTableSize),
  };
};

export const buildWidgetPanelState = (
  isNewWidget: boolean,
  panelState: WidgetPanelState,
  widgetConfig: ISummaryWidgetConfig,
  secondaryLensDataSource: DataSource[]
): WidgetPanelState => {
  if (isNewWidget) {
    return {
      ...panelState,
      selectedContinent: widgetConfig.continent,
      selectedRegion: widgetConfig.region,
      selectedCity: widgetConfig.city,
      selectedSiteId: widgetConfig.siteId,
      selectedSecondaryLens: first(secondaryLensDataSource)?.id as string,
    };
  }

  return {
    selectedContinent: widgetConfig.continent,
    selectedRegion: widgetConfig.region,
    selectedSecondaryLens: secondaryLensDataSource.find((item: DataSource) => {
      return item.label === widgetConfig.secondaryLens;
    })?.id as string,
    selectedCity: widgetConfig.city,
    selectedSiteId: widgetConfig.siteId,
    diffPriorValue: widgetConfig.showDifference ?? false,
    showDecimalValue: widgetConfig.showDecimal ?? false,
    selectedSumTableSize:
      widgetConfig.size === 4 ? WidgetSizes.full : WidgetSizes.half,
  };
};

export const buildWeightWidgetState = (
  isNewWidget: boolean,
  panelState: WeightedWidgetPanelState,
  widgetConfig: ISummaryWidgetConfig
): WeightedWidgetPanelState => {
  if (isNewWidget) {
    return {
      ...panelState,
      selectedContinent: widgetConfig.continent,
      selectedRegionId: widgetConfig.region,
      selectedRegionName: widgetConfig.region,
    };
  }
  return {
    selectedCategory: getValueFromDataSource(
      CategoryDataSourceList,
      widgetConfig.filter
    ),
    selectedContinent: widgetConfig.continent,
    selectedRegionName: widgetConfig.region,
    selectedRegionId: widgetConfig.siteId,
    selectedForecast: getValueFromDataSource(
      ForecastDataSourceList,
      widgetConfig.primaryLens
    ),
    selectedDailyPeriod: widgetConfig.dailyPeriod,
    selectedClimatology: widgetConfig.secondaryLens,
    selectedTableSize:
      widgetConfig.size === 4 ? WidgetSizes.full : WidgetSizes.half,
    selectedRunTimeModel: widgetConfig.runTime,
    selectedRunTimeData: widgetConfig.modelRun,
    selectedParameterValue: widgetConfig.modelForecast,
    diffPriorValue: widgetConfig.showDifference,
    showDecimalValue: widgetConfig.showDecimal,
    normalValue: widgetConfig.showNormal,
    biasCorrected: widgetConfig.biasCorrected,
  };
};

export const buildWidgetWeightDetails = (
  selectedContinentId: string,
  panelState: WeightedWidgetPanelState,
  tempUnit: string
): WeightedWidgetDetails => {
  return {
    continent: selectedContinentId,
    region: panelState.selectedRegionName,
    siteId: panelState.selectedRegionId,
    primaryLens: panelState.selectedForecast,
    secondaryLens: panelState.selectedClimatology,
    filter: panelState.selectedCategory,
    modelForecast: panelState.selectedParameterValue,
    runTime: panelState.selectedRunTimeModel,
    modelRun: panelState.selectedRunTimeData,
    showDecimal: panelState.showDecimalValue,
    showDifference: panelState.diffPriorValue,
    showNormal: panelState.normalValue,
    dailyPeriod: panelState.selectedDailyPeriod,
    biasCorrected: panelState.biasCorrected,
    tempUnit: tempUnit,
    size: getTableSize(panelState.selectedTableSize),
  };
};

export const prepareDataSource = (
  source: InitDataSources[]
): DropdownOption[] => {
  return source.map((runDate: InitDataSources) => {
    return {
      labelKey: runDate?.text,
      value: runDate?.id,
    };
  });
};

export const getTableSize = (type: string): number => {
  return type === WidgetSizes.full ? 4 : 2;
};

export const getValueFromDataSource = (
  sources: DropdownOption[],
  label: string
): string => {
  return (
    sources.find((source: DropdownOption) => {
      return source.labelKey === label;
    })?.value || ''
  );
};

export const prepareWidgetData = (
  data: ISummaryWidgetConfig,
  id: string
): WeightedPageState => {
  return {
    weightedRegion: data.siteId,
    weightedRunDate: data.modelRun,
    weightedContinent: data.continentId,
    filter: data.listName,
    modelForecastTab: data.modelForecast,
    modelForecast: data.modelForecast,
    primaryLens: data.primaryLensId,
    runTime: data.runTime,
    singleModelRun: data.modelRun,
    secondaryLens: data.secondaryLens,
    dailyPeriod: data.dailyPeriod,
    showBiasCorrected: data.biasCorrected,
    showDecimal: data.showDecimal,
    showForecastDifference: data.showDifference,
    showNormalsRow: data.showNormal,
    widgetId: id,
  };
};

export const hasModelRunTime = (forecast: string, runTime: string): boolean => {
  return (
    forecast === ForecastValues.model && runTime === RunTimeModelValues.runTime
  );
};

export const isIsoCountry = (value: string): boolean => {
  return (
    value === CategoryDataSourceList[0].value ||
    value === CategoryDataSourceList[0].labelKey
  );
};

import { Permissions } from '@firebird-web/acl';

export enum AvgTempSubTab {
  AVG_TEMP = 'AverageTemp',
  MIN_MAX = 'MinMax',
  OFF_PEAK_MIN_MAX = 'Peak',
  MAX_DEWPOINT = 'MaxDewpoint',
  MIN_MAX_FEELS_LIKE = 'MinMaxFeelsLike',
  HDD_CDD = 'DegreeDays',
  GAS_DAY = 'GasDay',
  CURRENT_TEMP = 'currentTemp',
}

export enum PrecipitationSubTab {
  PRECIP_AMOUNT = 'precipAmount',
  PROBABIILTY_OF_PRECIP = 'probabilityOfPrecip',
}

export type Filters = AvgTempSubTab | PrecipitationSubTab;

export enum RenewablesSubTab {
  FORECAST = 'forecast',
  FORECAST_COMPARISON = 'forecastComparison',
}

export enum LoadSubTab {}

export enum WeightedSubTab {
  ISO_FORECAST = 'iso',
  WEIGHTED_DEGREE_FORECAST = 'degree',
  US_GAS_DEMAND_FORECAST = 'gasDemand',
}

export type AvgTempSubTabTypes =
  | AvgTempSubTab.AVG_TEMP
  | AvgTempSubTab.MIN_MAX
  | AvgTempSubTab.OFF_PEAK_MIN_MAX
  | AvgTempSubTab.MAX_DEWPOINT
  | AvgTempSubTab.MIN_MAX_FEELS_LIKE
  | AvgTempSubTab.HDD_CDD
  | AvgTempSubTab.GAS_DAY
  | AvgTempSubTab.CURRENT_TEMP;

export type PrecipitationSubTabTypes =
  | PrecipitationSubTab.PRECIP_AMOUNT
  | PrecipitationSubTab.PROBABIILTY_OF_PRECIP;

export type RenewableSubTabTypes =
  | RenewablesSubTab.FORECAST
  | RenewablesSubTab.FORECAST_COMPARISON;

export type LoadSubTabTypes = '';

export type WeightedSubTabTypes =
  | WeightedSubTab.ISO_FORECAST
  | WeightedSubTab.US_GAS_DEMAND_FORECAST
  | WeightedSubTab.WEIGHTED_DEGREE_FORECAST;

export interface IOverviewSubTab {
  labelKey: string;
  value:
    | AvgTempSubTabTypes
    | PrecipitationSubTabTypes
    | RenewableSubTabTypes
    | LoadSubTabTypes
    | WeightedSubTabTypes;
  permission?: Permissions;
}

export interface IOverviewFilterTypes {
  type: string;
  case:
    | AvgTempSubTabTypes
    | PrecipitationSubTabTypes
    | RenewableSubTabTypes
    | LoadSubTabTypes
    | WeightedSubTabTypes;
}

export interface ForecastValuesState {
  showObsForecast: boolean;
  showComparison: boolean;
}
